@mixin desktop-lg() {
  // @for $i from 1 through length( $params ){
  // 	$id : to-lower-case( nth( $params, $i) );
  // 	.#{$id} {
  // 		.#{$id} &{
  // 			@content;
  // 		}
  // 	}
  // }
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
