.section-archive {
  @include desktop {
    padding-top: 100px;
  }
    @include mobile {
      padding-top: 50px;
    }
  .content {
    @include desktop {
      padding: 0 5.8%;
    }
    @include mobile {
      padding: 0 6.8%;
    }
  }
  .archive {
    display: block;
    position: relative;
    overflow: hidden;
    &:hover {
      .archive__img {
        @include desktop {
          transform: scale(1.05);
        }
      }
      .archive__title {
        @include desktop {
          color: #D8222A;
        }
      }
    }
    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      z-index: 2;
      transition: 300ms $ease-in-out;
      @include desktop {
        font-size: 12rem;
      }
      @include mobile {
        font-size: 5.5rem;
      }
    }
    &__img {
      width: 100%;
      transition: 300ms $ease-in-out;
      img {
        width: 100%;
      }
    }
  }
}
