.section-quotation-detail {
  .section-content {
    @include desktop {
      padding-bottom: 140px;
    }
  }
  .bg-wht-lg {
    @include desktop {
      padding-right: 25px;
      padding-left: 25px;
    }

    &__inner {
      background-color: #fff;
      padding-top: 22px;
      padding-right: 25px;
      padding-bottom: 190px;
      padding-left: 25px;
      position: relative;

      @include desktop {
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 33px;
        padding-right: 60px;
        padding-bottom: 200px;
        padding-left: 60px;
      }
    }
  }

  .group {
    padding: 0 10px;
    margin-bottom: 16px;

    @include desktop {
      padding: 0;
      margin-bottom: 32px;
    }

    &__inner {
      position: relative;
      width: 150px;

      @include desktop {
        width: 180px;
      }
    }

    select {
      background-color: #262626;
      color: #fff;
      height: 3.5rem;
      width: 100%;
      border-radius: 5px;
      box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
      padding: 0 24px 0 12px;
      position: relative;
      z-index: 1;

      @include desktop {
        border: none;
        height: 5rem;
        border-radius: 6px;
        padding: 0 34px 0 16px;
      }
    }

    .triangle {
      width: 8px;
      height: 6px;
      position: absolute;
      top: 50%;
      right: 14px;
      z-index: 2;
      transform: translate(0, -50%);
      background-color: $green-400;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      pointer-events: none;
    }
  }

  .columns-2 {
    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: start;

      > *:first-child {
        width: 55.5555%;
        min-width: 450px;
        margin-right: 20px;
      }

      > *:last-child {
        width: 40.7407%;
      }
    }
  }

  .quotation-data {
    margin-bottom: 20px;

    @include desktop {
      margin-left: -12px;
      margin-bottom: 35px;
    }
  }

  .detail-heading {
    margin-bottom: 16px;
    padding: 0 15px;
    display: flex;

    @include desktop {
      margin-bottom: 30px;
      padding: 0 20px;
    }

    &__icon {
      width: 82px;
      margin-right: 15px;

      @include desktop {
        width: 120px;
        margin-right: 35px;
      }

      img {
        width: 100%;
      }
    }

    &__body {
      padding-top: 10px;

      @include desktop {
        padding-top: 20px;
      }
    }

    &__label {
      padding: 2.5px 8.75px;
      background-color: $green-400;
      color: #fff;
      display: inline-block;
      border-radius: 3px;
      font-size: 0.9rem;

      @include desktop {
        font-size: 1.4rem;
        padding: 7.4px 17px;
        border-radius: 6px;
      }

      &_lawn {
        background-color: #9eb057;
      }

      &_felling {
        background-color: #998e44;
      }

      &_weeding {
        background-color: #70ad7a;
      }

      &_chemical {
        background-color: #5fb3af;
      }

      &_fertilization {
        background-color: #abb100;
      }
    }

    &__title {
      line-height: 1.25;
      font-size: 2rem;
      margin-top: 13px;

      @include desktop {
        font-size: 3rem;
        margin-top: 10px;
      }
    }
  }

  .detail-address {
    padding: 0 10px;
    margin-bottom: 18px;

    @include desktop {
      padding: 0;
      margin-bottom: 40px;
    }

    &__field {
      display: flex;
      align-items: center;

      @include desktop {
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }

  .detail-config {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;

    &_single {
      display: none;
    }

    &__head {
      background-color: $green-200;
      padding: 20px 17px 0;

      @include desktop {
        padding: 30px 30px 0;
      }
    }

    &__img {
      width: 100%;
      max-width: 400px;
      margin: auto;
    }

    &__body {
      background-color: $green-200;
      border-bottom: 1px solid #e5e5e5;
      padding: 20px 31px 20px 23px;
      min-height: 100px;
      display: flex;
      justify-content: center;

      > * {
        width: 50%;
      }

      @include desktop {
        padding: 30px 42px 28px 30px;
        min-height: 150px;
      }
    }

    &__defi {
      display: flex;
      align-items: baseline;
    }

    &__term {
      font-size: 1.1rem;
      margin-right: 6px;

      @include desktop {
        font-size: 1.4rem;
        margin-right: 11px;
      }

      label {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &__desc {
      position: relative;

      &.flex {
        display: flex;
        align-items: center;

        input {
          @include desktop {
            height: 5rem;
          }
          @include mobile {
            height: 3.5rem;
          }
        }
      }

      input {
        font-size: 1.5rem;
        background-color: #fff;
        text-align: center;
        padding: 0 0.5rem;
        height: 3.5rem;
        width: 50px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;

        @include desktop {
          font-size: 1.8rem;
          height: 5rem;
          width: 7rem;
          border-radius: 4px;
        }

        &.fullwide {
          width: 100%;

          @include desktop {
            height: 5rem;
          }
          @include mobile {
            height: 3.5rem;
          }
        }

        &.text {
          text-align: left;
          padding: 0 1em;
        }

        &:disabled {
          background: #ddd;
        }
      }

      select {
        border: 1px solid #e5e5e5;
        background-color: #fff;
        color: inherit;
        height: 3.5rem;
        min-width: 60px;
        border-radius: 5px;
        padding: 0 20px 0 12px;
        position: relative;
        z-index: 1;

        @include desktop {
          border: none;
          height: 5rem;
          min-width: 80px;
          border-radius: 6px;
          padding: 0 30px 0 16px;
        }

        &.coefficient {
          color: #000;
          font-size: 1.2rem;
          min-width: 60px;

          @include desktop {
            font-size: 1.6rem;
            min-width: 80px;
          }
        }

        &.fullwide {
          width: 100%;
        }
      }

      .triangle {
        width: 8px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 24px;
        z-index: 2;
        transform: translate(0, -50%);
        background-color: $green-400;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        pointer-events: none;

        @include desktop {
          right: 32px;
        }
      }

      select[name='number'] + .triangle,
      select[name='length'] + .triangle {
        right: 32px;
        @include desktop {
          right: 38px;
        }
      }

      select[name='premium'] + .triangle {
        right: 10px;
        @include desktop {
          right: 18px;
        }
      }

      .coefficient + .triangle {
        right: 10px;
      }

      .unit {
        // @include mobile {
        //   font-size: 1.5rem;
        // }
      }
    }

    &__num-wrap {
      // margin-right: 30px;
      // flex-shrink: 0;
      @include desktop {
        display: flex;
        justify-content: center;
      }

      .detail-config__term {
        margin-right: 16px;
      }
    }

    &__others-wrap {
      padding-left: 10px;
      margin-top: -8px;

      .detail-config__defi {
        margin-top: 8px;
      }

      @include desktop {
        padding-left: 40px;
        margin-top: -20px;

        > * {
          margin-top: 20px !important;
        }
        // display: flex;
        // align-items: start;
        // flex-wrap: wrap;
        // margin-bottom: -20px;
      }

      &.single {
        flex-grow: 1;

        .detail-config__term {
          flex-shrink: 0;
        }

        .detail-config__select {
          margin-right: 0;
          flex-grow: 1;
        }

        .detail-config__desc {
          flex-grow: 1;
        }
      }

      .detail-config__defi + .detail-config__defi {
        margin-top: 10px;

        @include desktop {
          margin-top: 20px;
        }
      }

      .detail-config__defi {
        &-wrap {
          margin-top: 10px;

          @include desktop {
            display: flex;
          }

          @include desktop {
            margin-top: 20px;
          }

          .detail-config__defi + .detail-config__defi {
            margin-top: 0;
            margin-left: 2em;

            @include mobile {
              margin-top: 10px;
            }
          }

          .unit {
            margin-left: 5px;
          }
        }
      }

      .detail-config__term {
        margin-right: 13px;
      }
    }

    &__select {
    }

    .dispose-btn {
      width: 40px;
      height: 20px;
      border-radius: 10px;
      background-color: $green-200;
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
      position: relative;
      transition: all 0.4s $easeOutQuint;

      @include desktop {
        width: 60px;
        height: 30px;
        border-radius: 15px;
      }

      &__toggle {
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 1;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
        transition: all 0.4s $easeOutQuint;

        @include desktop {
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
          width: 26px;
          height: 26px;
          top: 2px;
          left: 2px;
        }
      }
    }

    &__dispose {
      margin-top: 16px;

      @include desktop {
        margin-top: 30px;
      }

      label {
        height: 100%;
        display: flex;
        align-items: center;

        @include desktop {
          font-size: 1.4rem;
        }
        @include mobile {
          font-size: 1.1rem;
        }

        &[disabled] {
          opacity: 0.7;
        }
      }

      .dispose-btn {
        @include desktop {
          margin-left: 13px;
        }
        @include mobile {
          margin-left: 10px;
        }
      }

      input {
        display: none;

        &:checked + .dispose-btn {
          background-color: $green-400;
        }

        &:checked + .dispose-btn .dispose-btn__toggle {
          transform: translateX(20px);

          @include desktop {
            transform: translateX(30px);
          }
        }
      }
    }

    &__bottom {
      background-color: $primary-color;
      color: #fff;
      padding: 10px 10px 15px;

      @include desktop {
        padding: 25px 30px 23px;
      }
    }

    &__bottom-inner {
      @include desktop {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: -20px;
        margin-left: -30px;

        > * {
          margin-bottom: 20px;
          margin-left: 30px;
        }
      }
    }

    &__root-param-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        font-size: 1rem;
      }
    }

    &__root-param {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      &_edit {
        display: none;

        @include mobile {
          padding-top: 7px;
        }

        &.is-active {
          display: flex;
        }
      }

      &_disable {
        @include mobile {
          margin-bottom: -5px;
        }

        &.is-hide {
          display: none;
        }
      }

      .detail-config__defi + .detail-config__defi {
        margin-left: 10px;
      }

      &_edit {
        .detail-config__defi + .detail-config__defi {
          margin-left: 22px;
        }
      }

      input {
        margin-left: 4px;
      }

      input[id='unit-price'] {
        width: 80px;
        margin-right: 10px;

        @include desktop {
          width: 100px;
        }
      }
    }

    &__btn-wrap {
      margin-top: 12px;
      text-align: center;

      @include desktop {
        margin-top: 0;
        margin-left: 20px;
        display: flex;
        align-items: center;
      }
    }

    &__btn {
      border-color: #fff;
      font-size: 1.1rem;
      display: inline-block;
      width: auto;
      min-width: 100px;
      padding: 6.6px 12px;
      border-radius: 99px;

      @include desktop {
        font-size: 1.6rem;
        min-width: 160px;
        padding: 8.6px 15px;
      }

      &.is-active {
        border-color: #000;
        background-color: #000;
      }

      &.inactive {
        background: #aaa;
        border-color: #aaa;
        pointer-events: none;
      }

      &.js-reset-btn {
        display: none;
        background-color: rgba(255, 255, 255, 0.3);
        border-color: transparent;
        margin-right: 10px;

        &.is-active {
          display: inline-block;
        }
      }
    }
  }

  .title-sec {
    text-align: center;
    line-height: 1.25;

    @include desktop {
      text-align: left;
    }
  }

  .btn {
    padding: 9px 14.5px;
    border-radius: 15px;
    background-color: #e5e5e5;
    color: #a6a6a6;
    line-height: 1;
    display: inline-block;
    position: relative;
    transition: all 0.3s $easeOutQuint;

    @include desktop {
      padding: 12px 22px;
      border-radius: 20px;
    }

    &:hover {
      @include desktop {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  .label {
    padding: 9px 14.5px;
    border-radius: 15px;
    background-color: #e5e5e5;
    color: #a6a6a6;
    line-height: 1;
    display: inline-block;
    position: relative;
    transition: all 0.3s $easeOutQuint;

    @include desktop {
      padding: 12px 22px;
      border-radius: 20px;
    }

    &__circle {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 1;
      width: 20px;
      height: 20px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      transform: scale(0, 0);
      transition: all 0.3s $easeOutQuint;

      @include desktop {
        width: 30px;
        height: 30px;
      }
    }

    &__check {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      width: 9px;
      height: 5px;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      transition: all 0.3s $easeOutQuint;

      @include desktop {
        width: 15px;
        height: 7px;
      }
    }

    &.is-active {
      background-color: $primary-color;
      color: #fff;
      padding-left: 29.5px;

      @include desktop {
        padding-left: 44px;
      }

      // .label__circle {
      //   transform: scale(1, 1);
      // }

      // .label__check {
      //   transform: translate(-50%, -50%) rotate(-45deg);
      // }
    }

    input:checked {
      + .label__circle {
        transform: scale(1, 1);

        .label__check {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  .add-label-list {
    margin-bottom: 28px;

    // @include desktop {
    //   margin-bottom: 0;
    // }

    .add-label-list__item + .add-label-list__item {
      margin-top: 28px;

      @include desktop {
        margin-top: 50px;
      }
    }
  }

  .add-label {
    &__heading {
      margin-bottom: 13px;

      @include desktop {
        margin-bottom: 25px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-right: -10px;
      margin-bottom: -10px;

      @include desktop {
        justify-content: left;
        margin-bottom: -20px;
      }
    }

    &__item {
      margin-right: 10px;
      margin-bottom: 10px;

      @include desktop {
        margin-bottom: 20px;
      }
    }
  }

  .add-note {
    margin-bottom: 17px;

    @include desktop {
      margin-bottom: 0;
    }

    &__item + .add-note__item {
      margin-top: 24px;
    }

    &__heading {
      margin-bottom: 13px;
    }

    &__field {
      height: auto;
      padding-top: 0.9em;
      padding-bottom: 0.9em;

      @include desktop {
        height: 170px;
      }
    }
  }

  .caution {
    font-weight: normal;
    font-size: 1.1rem;

    @include desktop {
      font-size: 1.4rem;
      padding-top: 10px;
    }
  }

  .back-btn {
    position: absolute;
    top: 0;
    right: 15px;
    transform: translate(0, -50%);
    z-index: 1;
    display: none;

    @include desktop {
      right: 60px;
      display: block;
    }
  }

  .detail-total {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: 10px;

    @include desktop {
      padding: 0;
    }

    &__inner {
      background-color: #262626;
      padding: 22.5px 20px 22.5px 29px;
      color: #fff;
      border-radius: 5px;
      box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);

      @include desktop {
        padding: 25px;
        border-radius: 0;
        box-shadow: none;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include desktop {
        max-width: 1160px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &__price {
      font-size: 3rem;
      line-height: 1;

      @include desktop {
        font-size: 3.6rem;
      }

      &::before {
        content: '合計';
        font-size: 1.2rem;
        margin-right: 11px;

        @include desktop {
          font-size: 1.8rem;
          margin-right: 20px;
        }
      }

      &::after {
        content: '円';
        font-size: 1.8rem;
      }

      &_coef {
        &::before {
          content: '係数';
        }

        &::after {
          content: '';
        }
      }
    }

    &__btn {
      background-color: $primary-color;
      color: #fff;
      padding: 6.7px 10px;
      border-radius: 3px;
      width: 100%;
      max-width: 110px;
      text-align: center;

      @include desktop {
        font-size: 1.8rem;
        border-radius: 6px;
        max-width: 220px;
        padding: 18.8px 1em;
      }

      &.inactive {
        background: #aaa;
        pointer-events: none;
      }
    }
  }

  .check-list {
    margin-bottom: 50px;

    &__item {
      + .check-list__item {
        margin-top: 10px;
      }

      input[type='checkbox'] {
        display: none;
        & + span {
          cursor: pointer;
          display: inline-block;
          margin: 0 0.2em 0;
          padding: 0 0 0 1.7em;
          position: relative;
          &::before {
            background: #fff;
            border: 1px solid #dadada;
            border-radius: 2px;
            content: '';
            display: block;
            height: 1em;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1em;
          }
          &::after {
            border-bottom: 3px solid $primary-color;
            border-left: 3px solid $primary-color;
            content: '';
            display: block;
            height: 0.6em;
            left: 0;
            margin-top: -0.2em;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            transition: all 0.3s ease 0s;
            width: 1em;
          }
        }
        &:checked {
          & + span {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.option {
    .bg-wht-lg {
      &__inner {
        @include desktop {
          padding-bottom: 100px;
        }
        @include mobile {
          padding-bottom: 80px;
        }
      }
    }
    .columns-2 {
      @include desktop {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        > *:first-child {
          width: 50%;
          min-width: auto;
          margin-right: 20px;
        }

        > *:last-child {
          width: 50%;
        }
      }
    }

    .validation-toggle-label {
      display: inline-flex;
      align-items: center;
    }

    .validation-toggle {
      display: none;
      & + span {
        cursor: pointer;
        display: inline-block;
        margin: 0 0.3em 0;
        padding: 0 0 0 1.2em;
        position: relative;
        &::before {
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.16);
          border-radius: 2px;
          content: '';
          display: block;
          height: 1em;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1em;
        }
        &::after {
          border-bottom: 3px solid $primary-color;
          border-left: 3px solid $primary-color;
          content: '';
          display: block;
          height: 0.6em;
          left: 0;
          margin-top: -0.2em;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          transition: all 0.3s ease 0s;
          width: 1em;
        }
      }
      &:checked {
        & + span {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .detail-heading {
      .inactive {
        background-color: #aaa;
      }
    }
    .detail-config {
      &__title {
        font-size: 1.6rem;

        @include desktop {
          font-size: 2rem;
        }
      }
      background-color: $green-200;
      &__body {
        display: block;
        border-bottom: none;
        padding: 6px 17px 23px;

        @include desktop {
          padding: 10px 30px 30px;
        }
      }

      &__desc {
        input {
          width: 100%;
          text-align: left;
          padding-left: 1em;
          @include desktop {
            height: 5rem;
            font-size: 16px;
          }
          @include mobile {
            height: 3.5rem;
            font-size: 12px;
          }
        }
      }

      &__term {
        @include desktop {
          margin-left: 10px;
          margin-right: 0;
        }
        @include mobile {
          margin-left: 2.5px;
        }
      }

      &__defi {
        &:not(:first-child) {
          @include desktop {
            margin-left: 10px;
          }
          @include mobile {
            margin-left: 5px;
          }
        }
      }
    }

    .detail-config-wrap {
      width: auto;
    }

    .special-vehicle,
    .disposal-cost,
    .other {
      .detail-config {
        &__defi {
          width: 100%;
        }
        &__desc {
          width: 100%;
        }
      }
    }

    .road-permit {
      .detail-config {
        &-wrap {
          justify-content: flex-end;
        }
        &__defi {
          width: 50%;
        }
        &__term {
          white-space: nowrap;
          margin-left: 0;
          @include desktop {
            margin-right: 10px;
          }
          @include mobile {
            margin-right: 5px;
          }
        }
      }
    }

    .detail-config-wrap {
      display: flex;
      justify-content: space-between;
      @include desktop {
      }
      @include mobile {
      }

      &.detail-config-wrap:not(:first-child) {
        @include desktop {
          margin-top: 20px;
        }
        @include mobile {
          margin-top: 10px;
        }
      }
    }

    .discount {
      .detail-config {
        color: #fff;
        background-color: #47885e;

        &-wrap {
          justify-content: flex-end;
        }
        &__head {
          background-color: #47885e;
        }
        &__body {
          background-color: #47885e;
        }
        &__term {
          display: flex;
          align-items: center;
        }

        &__defi + span {
          text-align: center;
          display: flex;
          align-items: center;
          width: 20%;
          font-size: 1rem;
          @include desktop {
            margin-left: 20px;
            font-size: 1.4rem;
          }
          @include mobile {
            margin-left: 5px;
          }
        }
      }
    }

    .discount {
      .detail-config.premium-price {
        background-color: #eef3e3;
        color: #000;

        .detail-config__head,
        .detail-config__body {
          background-color: #eef3e3;
        }

        .detail-config-wrap {
          justify-content: start;
        }
      }
    }

    .detail-total {
      &__container {
        align-items: flex-end;
        @include desktop {
        }
        @include mobile {
        }
      }

      &__price {
        font-size: 3rem;
        line-height: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include desktop {
          font-size: 3.6rem;
        }

        &::before {
          content: '合計';
          font-size: 1.2rem;
          margin-right: 11px;
          width: 100%;
          margin-bottom: 0.5em;
          @include desktop {
            font-size: 1.8rem;
            margin-right: 20px;
          }
        }

        &:not(:first-of-type) {
          margin-top: 0.5em;
        }
        &.campaign,
        &.member {
          &::before {
            content: '割引';
            @include desktop {
              font-size: 1.4rem;
            }
            @include mobile {
              font-size: 1.2rem;
            }
          }
          &::after {
            content: '%';
            font-size: 1.8rem;
          }
        }
        &.industry {
          &::before {
            content: '値引';
            @include desktop {
              font-size: 1.4rem;
            }
            @include mobile {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    .detail-config_calc {
      font-size: 1.6rem;

      @include desktop {
        font-size: 1.8rem;
      }

      .detail-config__body {
        padding: 20px 17px 23px;

        @include desktop {
          padding: 30px;
        }
      }
    }
  }
}
