.section-customer {
  @include desktop {
    padding-top: 75px;
    padding-bottom: 60px;
  }
  @include mobile {
    padding-top: 30px;
    padding-bottom: 50px;
  }


  .headline {
    &__title {
      line-height: 1;

      @include desktop {
        font-size: 3.6rem;
      }
      @include mobile {
        font-size: 2rem;
      }
    }
  }
}
