.section-quotation {
  padding-bottom: 20px;

  @include desktop {
    padding-bottom: 90px;
  }

  .content {
    @include desktop {
      display: flex;
    }
  }

  .add-btn-wrap {
    text-align: center;

    @include desktop {
      width: 300px;
      margin-right: 4.16667%;
    }
  }

  .add-btn {
    color: #fff;
    background-color: $primary-color;
    width: 100%;
    max-width: 235px;
    font-size: 1.5rem;
    border-radius: 5px;
    text-align: center;
    padding: 1.15rem 1.5rem;
    box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);
    display: inline-block;

    @include desktop {
      max-width: none;
      border: 2px solid transparent;
      padding: 2.18rem 1.5rem;
      border-radius: 10px;
      font-size: 1.8rem;
      box-shadow: none;
      transition: all 0.3s $easeOutQuint;

      &:hover {
        background-color: #fff;
        border-color: $primary-color;
        color: $primary-color;
      }
    }
  }

  .quotation-list {
    margin-top: 30px;

    @include desktop {
      margin-top: 0;
      width: 850px;
    }

    .quotation-list__item + .quotation-list__item {
      margin-top: 20px;

      @include desktop {
        margin-top: 30px;
      }
    }
  }

  .quotation {
    padding: 14px 10px;

    &_highlight {
      padding: 11.5px 7.5px;
      border: 2.5px solid #cd2;
    }

    @include desktop {
      padding: 28px 50px 31px;
    }

    &__head {
      padding-bottom: 13px;
      padding-left: 8px;
      border-bottom: 1px solid #f6f6f6;

      @include desktop {
        padding-bottom: 32px;
        padding-left: 0;
      }
    }

    .quotation-data {
      position: relative;

      @include desktop {
        padding-top: 15px;
      }

      &__num {
        position: relative;
      }

      &__label-wrap {
        display: inline-flex;
        align-items: center;

        @include desktop {
          position: absolute;
          bottom: calc(100% + 12px);
          left: 0;

          > *:first-of-type {
            margin-left: 0;
          }
        }
      }

      &__text {
        @include desktop {
          display: flex;
          align-items: center;
        }
      }

      &__btn-wrap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }

      &__edit-btn {
        width: 30px;
        height: 30px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s $easeOutQuint;

        @include desktop {
          width: 40px;
          height: 40px;
        }

        .dot {
          display: inline-block;
          width: 3px;
          height: 3px;
          background-color: #252626;
          border-radius: 50%;
          transition: all 0.3s $easeOutQuint;

          @include desktop {
            width: 4px;
            height: 4px;
          }

          + .dot {
            margin-left: 2px;
          }
        }

        &.is-active {
          background-color: #666;
          border-color: transparent;

          .dot {
            background-color: #fff;
          }
        }
      }
    }

    .edit-list-bg {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100vh;
      visibility: hidden;

      &.is-active {
        visibility: visible;
      }
    }

    .edit-list {
      position: absolute;
      top: 100%;
      right: 50%;
      transform: translate(39px, 12px);
      z-index: 2;
      background-color: #fff;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 5px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s $easeOutQuint;

      &.is-active {
        visibility: visible;
        opacity: 1;
      }

      &::before {
        content: '';
        position: absolute;
        right: 35px;
        bottom: 100%;
        z-index: 1;
        width: 8px;
        height: 10px;
        clip-path: polygon(50% 0%, 0 100%, 100% 100%);
        background-color: #fff;
      }

      &__inner {
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .edit-list__item + .edit-list__item {
          margin-left: 1px;
        }
      }

      &__btn {
        width: 50px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: end;
        color: #fff;
        padding: 8px;
        font-size: 1.1rem;

        &_edit {
          background-color: #22b9b7;
        }

        &_copy {
          background-color: #228db9;
        }

        &_delete {
          background-color: #da294a;
        }

        &_pdf {
          background-color: #976fa6;
        }
      }

      &__btn-text {
        margin-top: 6px;
        display: block;
        line-height: 1;
      }
    }
  }

  .stack-list {
    &__item {
      border-bottom: 1px solid #f6f6f6;
      padding: 4.6px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem;

      @include desktop {
        font-size: 1.6rem;
        padding: 10.1px 13px;
      }
    }

    &__desc {
      font-size: 1.1rem;
      flex-shrink: 0;

      @include desktop {
        font-size: 1.4rem;
      }
    }
  }

  .total-price {
    padding: 12px 17px 0;
    display: flex;
    justify-content: space-between;
    line-height: 1;

    @include desktop {
      padding: 21px 13px 0;
    }

    &__term {
      @include desktop {
        font-size: 1.8rem;
      }
    }

    &__desc {
      font-size: 1.5rem;

      @include desktop {
        font-size: 1.8rem;
      }

      .unit {
        font-size: 1.2rem;

        @include desktop {
          font-size: 1.4rem;
        }
      }
    }
  }
}
