.section-customer {
  padding-top: 21px;
  padding-bottom: 19px;

  @include desktop {
    padding-top: 43px;
    padding-bottom: 30px;
  }

  .customer {
    display: flex;

    @include mobile {
      // flex-wrap: wrap;
    }

    &__img {
      width: 25px;
      margin-right: 16px;
      flex-shrink: 0;

      @include desktop {
        width: 34px;
        margin-right: 22px;
      }

      img {
        width: 100%;
      }
    }

    &__body {
      margin-bottom: 10px;

      @include desktop {
        margin-right: 32px;
        margin-bottom: 0;
      }
      @include mobile {
        margin-right: 15px;
        max-width: calc(100% - 63px);
      }
    }

    &__company {
      margin-bottom: 4px;
    }

    &__name {
      font-size: 3rem;
      line-height: 1;
      word-break: break-all;

      @include desktop {
        font-size: 4.2rem;
      }

      &::after {
        content: '様';
        margin-left: 10px;
        font-size: 1.5rem;

        @include desktop {
          font-size: 2.1rem;
          margin-left: 15px;
        }
      }
    }

    &__text {
      font-weight: normal;
      font-size: 0.9rem;
      margin-top: 13px;
      line-height: 1.7;

      @include desktop {
        font-size: 1.3rem;
        margin-top: 18px;
      }
    }

    &__btn-wrap {
      flex-shrink: 0;

      @include desktop {
        min-width: 154px;
      }
    }

    &__rounded-btn {
      background-color: #252626;
      max-width: 110px;
      font-size: 1.1rem;
      padding: 4px 15px;
      text-align: center;

      @include desktop {
        font-size: 1.5rem;
        padding: 5.5px 10px;
        max-width: 154px;
        border-radius: 21px;
        transition: all 0.3s $easeOutQuint;
        border-width: 2px;

        &:hover {
          background-color: #fff;
          border-color: #000;
        }
      }
    }
  }
}
