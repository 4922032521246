.section-login {
  background: url(../img/login/login-bg.jpg);
  background-size: cover;
  height: 100vh;
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    background: url(../img/login/login-bg-pc.jpg);
    background-size: cover;
  }

  .section-content {
    width: 100%;
    padding: 50px 0;
  }

  .login {
    padding: 32px 45px 20px;

    @include desktop {
      padding: 57px 60px 30px;
    }

    &__title {
      text-align: center;
      margin-bottom: 32px;
      max-width: 274px;
      margin-right: auto;
      margin-left: auto;

      @include desktop {
        margin-bottom: 46px;
      }
    }

    &__form {
      &.is-error {
        .login__error {
          display: block;
        }
      }

      &.is-failed {
        .login__failed {
          display: block;
        }
      }
    }

    &-input {
      position: relative;

      + .login-input {
        margin-top: 10px;
      }

      &.pass {
        button {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          background-repeat: no-repeat;
          cursor: pointer;
          transition: 100ms;
          background-position: center;
    
          @include desktop {
            height: 6rem;
            width: 6rem;
            background-size: 25px;
          }
          @include mobile {
            height: 4rem;
            width: 4rem;
            background-size: 17px;
          }

          &.visible {
            background-image: url(../img/login/icon-pass_show.png);
          }

          &.invisible {
            background-image: url(../img/login/icon-pass_hide.png);
          }
        }

        .login__field {
          @include desktop {
            padding-right: 6rem;
          }
          @include mobile {
            padding-right: 4rem;
          }
        }
      }
    }

    &__field {
      &::placeholder {
        font-size: 0.9rem;
        color: #b2b2b2;

        @include desktop {
          font-size: 1.4rem;
        }
      }
    }

    &__btn {
      font-size: 1.2rem;
      padding: 1.42rem 1.5rem;
      margin-top: 25px;
      max-width: none;

      @include desktop {
        font-size: 1.6rem;
        padding: 2.56rem 1.5rem;
        margin-top: 33px;
      }
    }

    &__lost {
      margin-top: 1.5em;

      a {
        @include desktop {
          font-size: 1.4rem;
        }
        @include mobile {
          font-size: 1.3rem;
        }
  
        &:hover {
          @include desktop {
            text-decoration: underline;
          }
        }
      }
    }

    &__copy {
      font-size: 0.9rem;
      color: #8c8c8c;
      margin-top: 32px;
      text-align: center;

      @include desktop {
        margin-top: 69px;
        font-size: 1.2rem;
      }
    }

    &__error,
    &__failed {
      display: none;
      color: #f00;
      margin-top: 1em;

      @include desktop {
        font-size: 1.4rem;
      }
      @include mobile {
        font-size: 1.3rem;
      }
    }
  }
}
