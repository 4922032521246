@charset "UTF-8";
@media only screen and (min-width: 768px) {
  .is-top .localnav {
    padding: 70px 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .localnav {
    padding: 50px 20px;
  }
}
.is-top .localnav-item {
  text-align: center;
}
.is-top .localnav-item a {
  transition: 200ms cubic-bezier(0.42, 0, 0.58, 1);
}
@media only screen and (min-width: 768px) {
  .is-top .localnav-item a {
    font-size: 12rem;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .localnav-item a {
    font-size: 5rem;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .localnav-item a:hover {
    color: #D8222A;
  }
}
.is-top .section-hero .section-content {
  position: relative;
}
.is-top .section-hero .swiper-container.slideNone .swiper-button-prev,
.is-top .section-hero .swiper-container.slideNone .swiper-button-next {
  display: none;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-button-prev, .is-top .section-hero .swiper-button-next {
    height: 45px;
    width: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-prev, .is-top .section-hero .swiper-button-next {
    height: 42px;
    width: 42px;
  }
}
.is-top .section-hero .swiper-button-prev::after, .is-top .section-hero .swiper-button-next::after {
  content: "";
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-prev::after, .is-top .section-hero .swiper-button-next::after {
    background-size: auto 22px;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-button-prev {
    left: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-prev {
    left: 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-button-prev::after {
    background-image: url(../img/top/icon-arrow_prev.png);
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-prev::after {
    background-image: url(../img/top/icon-arrow_prev-sp.png);
  }
}
.is-top .section-hero .swiper-button-next {
  left: auto;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-button-next {
    right: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-next {
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-button-next::after {
    background-image: url(../img/top/icon-arrow_next.png);
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-button-next::after {
    background-image: url(../img/top/icon-arrow_next-sp.png);
  }
}
.is-top .section-hero .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.5;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    margin: 0 0.4em;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 0.3em;
  }
}
.is-top .section-hero .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
.is-top .section-hero .swiper-slide {
  position: relative;
  overflow: hidden;
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
}
.is-top .section-hero .swiper-slide a {
  display: block;
  height: 100%;
}
.is-top .section-hero .swiper-slide .video,
.is-top .section-hero .swiper-slide .video-embed {
  pointer-events: none;
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  width: 100vw;
  height: 150%;
  min-width: 500%;
  min-height: 1600px;
}
.is-top .section-hero .swiper-slide .video iframe,
.is-top .section-hero .swiper-slide .video-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-width: 0;
}
.is-top .section-hero .swiper-slide .hero-img {
  width: 100%;
  height: 100%;
}
.is-top .section-hero .swiper-slide .hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.is-top .section-hero .hero-headline {
  position: absolute;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .hero-headline {
    bottom: 10.455vh;
    left: 65px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .hero-headline {
    bottom: 45px;
    left: 25px;
  }
}
.is-top .section-hero .hero__title {
  color: #fff;
  line-height: 1.2;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .hero__title {
    font-size: 9rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .hero__title {
    font-size: 4.5rem;
  }
}
.is-top .section-hero .hero__subtitle {
  color: #fff;
  line-height: 1.2;
}
@media only screen and (min-width: 768px) {
  .is-top .section-hero .hero__subtitle {
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-hero .hero__subtitle {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection + .section-intro {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-collection + .section-intro {
    padding-top: 45px;
  }
}
.is-top .section-collection.videoPlay .collection-inner {
  opacity: 0;
  visibility: hidden;
}
.is-top .section-collection.videoPlay .btn-wrap {
  opacity: 0;
  visibility: hidden;
}
.is-top .section-collection.videoPlay .video {
  min-width: 100%;
  min-height: 100%;
}
.is-top .section-collection .content {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: #fff no-repeat top center;
  background-size: cover;
}
.is-top .section-collection .collection {
  height: 100%;
  position: relative;
}
.is-top .section-collection .collection-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 5;
  text-align: center;
  width: 90%;
  transition: opacity 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
.is-top .section-collection .collection__title {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .collection__title {
    font-size: 8vmin;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-collection .collection__title {
    font-size: 4.5rem;
  }
}
.is-top .section-collection .collection__cat {
  color: #fff;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .collection__cat {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-collection .collection__cat {
    font-size: 1.5rem;
  }
}
.is-top .section-collection .collection .play-btn {
  position: relative;
  display: block;
  background-image: url(../img/common/icon-play_active.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translate(-40%, 100%);
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .collection .play-btn {
    bottom: -4.09vh;
    width: 11.8vh;
    height: 11.8vh;
    max-width: 130px;
    max-height: 130px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-collection .collection .play-btn {
    bottom: -20px;
    width: 65px;
    height: 65px;
  }
}
.is-top .section-collection .collection .play-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100.1%;
  height: 100.1%;
  background-image: url(../img/common/icon-play.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .collection .play-btn:hover::before {
    opacity: 0;
  }
}
.is-top .section-collection .btn-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  transition: opacity 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .btn-wrap {
    bottom: 9.54vh;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-collection .btn-wrap {
    bottom: 9vh;
  }
}
.is-top .section-collection .btn-wrap .btn {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .is-top .section-collection .btn-wrap .btn:hover span::before {
    background-color: #D8222A;
  }
}
.is-top .section-collection .btn-wrap .btn span::before {
  background-color: #fff;
}
.is-top .section-collection .video {
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  width: auto;
  height: auto;
  min-width: 500%;
  min-height: 165%;
}
.is-top .section-collection .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-width: 0;
}
.is-top .section-collection .video.isPlay {
  min-width: 100%;
  min-height: 100%;
}
.is-top .section-collection .thumb {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  opacity: 1;
  background-color: black;
}
.is-top .section-collection .thumb.hide {
  z-index: 0;
}
@media only screen and (min-width: 768px) {
  .is-top .section-archive {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-archive {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-archive .content {
    padding: 0 5.8%;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-archive .content {
    padding: 0 6.8%;
  }
}
.is-top .section-archive .archive {
  display: block;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .is-top .section-archive .archive:hover .archive__img {
    transform: scale(1.05);
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-archive .archive:hover .archive__title {
    color: #D8222A;
  }
}
.is-top .section-archive .archive__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
  transition: 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
@media only screen and (min-width: 768px) {
  .is-top .section-archive .archive__title {
    font-size: 12rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-archive .archive__title {
    font-size: 5.5rem;
  }
}
.is-top .section-archive .archive__img {
  width: 100%;
  transition: 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
.is-top .section-archive .archive__img img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .is-top .section-intro {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .section-content {
    padding: 0 6.8%;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro .cell:not(:nth-of-type(2)) {
    padding-top: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro .cell:first-of-type {
    order: 2;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro .cell:nth-of-type(2) {
    order: 1;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro .cell:nth-of-type(3) {
    order: 3;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro .cell:last-of-type {
    order: 4;
  }
}
.is-top .section-intro .intro .panel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.is-top .section-intro .intro .panel.comming-soon {
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-top .section-intro .intro .panel:hover .intro-img {
    transform: scale(1.05);
  }
}
@media only screen and (min-width: 768px) {
  .is-top .section-intro .intro .panel:hover .intro__title {
    color: #D8222A;
  }
}
.is-top .section-intro .intro-img {
  transition: 300ms cubic-bezier(0.42, 0, 0.58, 1);
  width: 100%;
  height: 100%;
}
.is-top .section-intro .intro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.is-top .section-intro .intro__title {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 300ms cubic-bezier(0.42, 0, 0.58, 1);
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-top .section-intro .intro__title {
    font-size: 7.2vmax;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro__title {
    font-size: 5rem;
  }
}
.is-top .section-intro .intro__title.bk {
  color: #000;
}
.is-top .section-intro .intro__title span {
  display: block;
  line-height: 1.2;
}
@media only screen and (min-width: 768px) {
  .is-top .section-intro .intro__title span {
    font-size: 2.5vmax;
  }
}
@media only screen and (max-width: 768px) {
  .is-top .section-intro .intro__title span {
    font-size: 2rem;
  }
}

.is-login .section-login {
  background: url(../img/login/login-bg.jpg);
  background-size: cover;
  height: 100vh;
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login {
    background: url(../img/login/login-bg-pc.jpg);
    background-size: cover;
  }
}
.is-login .section-login .section-content {
  width: 100%;
  padding: 50px 0;
}
.is-login .section-login .login {
  padding: 32px 45px 20px;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login {
    padding: 57px 60px 30px;
  }
}
.is-login .section-login .login__title {
  text-align: center;
  margin-bottom: 32px;
  max-width: 274px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__title {
    margin-bottom: 46px;
  }
}
.is-login .section-login .login__form.is-error .login__error {
  display: block;
}
.is-login .section-login .login__form.is-failed .login__failed {
  display: block;
}
.is-login .section-login .login-input {
  position: relative;
}
.is-login .section-login .login-input + .login-input {
  margin-top: 10px;
}
.is-login .section-login .login-input.pass button {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 100ms;
  background-position: center;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login-input.pass button {
    height: 6rem;
    width: 6rem;
    background-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-login .section-login .login-input.pass button {
    height: 4rem;
    width: 4rem;
    background-size: 17px;
  }
}
.is-login .section-login .login-input.pass button.visible {
  background-image: url(../img/login/icon-pass_show.png);
}
.is-login .section-login .login-input.pass button.invisible {
  background-image: url(../img/login/icon-pass_hide.png);
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login-input.pass .login__field {
    padding-right: 6rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-login .section-login .login-input.pass .login__field {
    padding-right: 4rem;
  }
}
.is-login .section-login .login__field::placeholder {
  font-size: 0.9rem;
  color: #b2b2b2;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__field::placeholder {
    font-size: 1.4rem;
  }
}
.is-login .section-login .login__btn {
  font-size: 1.2rem;
  padding: 1.42rem 1.5rem;
  margin-top: 25px;
  max-width: none;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__btn {
    font-size: 1.6rem;
    padding: 2.56rem 1.5rem;
    margin-top: 33px;
  }
}
.is-login .section-login .login__lost {
  margin-top: 1.5em;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__lost a {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-login .section-login .login__lost a {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__lost a:hover {
    text-decoration: underline;
  }
}
.is-login .section-login .login__copy {
  font-size: 0.9rem;
  color: #8c8c8c;
  margin-top: 32px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__copy {
    margin-top: 69px;
    font-size: 1.2rem;
  }
}
.is-login .section-login .login__error, .is-login .section-login .login__failed {
  display: none;
  color: #f00;
  margin-top: 1em;
}
@media only screen and (min-width: 768px) {
  .is-login .section-login .login__error, .is-login .section-login .login__failed {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-login .section-login .login__error, .is-login .section-login .login__failed {
    font-size: 1.3rem;
  }
}

.is-quotation .section-quotation {
  padding-top: 15px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation {
    padding-top: 20px;
  }
}
.is-quotation .section-quotation .headline {
  margin-bottom: 25px;
}
.is-quotation .section-quotation .headline__title {
  padding-left: 3px;
  font-size: 2rem;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .headline__title {
    padding-left: 6px;
    font-size: 3.6rem;
  }
}
.is-quotation .section-quotation .quotation {
  padding: 20px 15px 27px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation {
    padding: 33px 60px 58px;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__quotation-data {
    margin-left: -12px;
  }
}
.is-quotation .section-quotation .quotation__group {
  padding-top: 8px;
  padding-bottom: 4px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__group {
    padding-top: 16px;
  }
}
.is-quotation .section-quotation .quotation__operate {
  margin-top: 23px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__operate {
    margin-top: 71px;
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__operate-left {
    width: 710px;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__operate-right {
    margin-left: 30px;
    width: 340px;
  }
}
.is-quotation .section-quotation .quotation__edit-list {
  margin-top: -8px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__edit-list {
    margin-top: -16px;
  }
}
.is-quotation .section-quotation .quotation__edit-item + .quotation__edit-item {
  margin-top: 10px;
}
.is-quotation .section-quotation .quotation__add-btn-wrap {
  margin-top: 25px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__add-btn-wrap {
    margin-top: 0;
    padding-top: 43px;
  }
}
.is-quotation .section-quotation .quotation__add-btn {
  width: auto;
  padding-right: 25px;
  padding-left: 25px;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.is-quotation .section-quotation .quotation__add-btn.is-active {
  background-color: #fff;
  color: #000;
  border-color: #d9d9d9;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__add-btn {
    width: 100%;
    max-width: 260px;
    border-radius: 40px;
    padding: 23.6px 16px;
    border-width: 2px;
    border-color: #000;
    padding-right: 15px;
    padding-left: 15px;
  }
  .is-quotation .section-quotation .quotation__add-btn:hover {
    background-color: #fff;
    color: #000;
  }
}
.is-quotation .section-quotation .quotation__back-btn {
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation__back-btn {
    right: 60px;
  }
}
.is-quotation .section-quotation .quotation-edit {
  background-color: #f6f6f6;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit {
    background-color: #fff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }
}
.is-quotation .section-quotation .quotation-edit > *:not(:first-child) {
  flex-shrink: 0;
}
.is-quotation .section-quotation .quotation-edit_highlight {
  position: relative;
}
.is-quotation .section-quotation .quotation-edit_highlight::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2.5px solid #cd2;
  pointer-events: none;
}
.is-quotation .section-quotation .quotation-edit__heading {
  flex-grow: 1;
  font-size: 1.1rem;
  padding: 11.31px 10px 11.31px 21px;
  line-height: 1.7;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__heading {
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 16px 16px 16px 32px;
  }
}
.is-quotation .section-quotation .quotation-edit__title {
  display: flex;
  align-items: center;
}
.is-quotation .section-quotation .quotation-edit__label {
  margin-left: 6px;
}
.is-quotation .section-quotation .quotation-edit__btn {
  width: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn {
    transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn:hover {
    opacity: 0.8;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn {
    width: 70px;
  }
}
.is-quotation .section-quotation .quotation-edit__btn_edit {
  background-color: #47885e;
}
.is-quotation .section-quotation .quotation-edit__btn_delete {
  background-color: #da294a;
  height: 100%;
}
.is-quotation .section-quotation .quotation-edit__btn-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.is-quotation .section-quotation .quotation-edit__btn-icon {
  margin-bottom: 7px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn-icon {
    margin-bottom: 8px;
  }
}
.is-quotation .section-quotation .quotation-edit__btn-icon img {
  display: block;
  width: 25px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn-icon img {
    width: 30px;
  }
}
.is-quotation .section-quotation .quotation-edit__btn-text {
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .quotation-edit__btn-text {
    font-size: 1.4rem;
  }
}
.is-quotation .section-quotation .quotation-edit__line {
  width: 1px;
  background-color: #fff;
}
.is-quotation .section-quotation .total-price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 16px;
  padding-left: 11px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .total-price {
    width: calc((100% - 30px) / 2);
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }
}
.is-quotation .section-quotation .total-price__desc {
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .total-price__desc {
    font-size: 2.4rem;
  }
}
.is-quotation .section-quotation .total-price__desc::after {
  content: "円";
  font-size: 1.2rem;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .total-price__desc::after {
    font-size: 1.6rem;
  }
}
.is-quotation .section-quotation .image-upload__bg {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 16px 15px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload__bg {
    border-radius: 10px;
    padding: 40px;
    margin-top: 50px;
  }
}
.is-quotation .section-quotation .image-upload__title {
  text-align: center;
  margin-bottom: 13px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload__title {
    margin-bottom: 24px;
  }
}
.is-quotation .section-quotation .image-upload .image-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload .image-list {
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-bottom: 30px;
  }
}
.is-quotation .section-quotation .image-upload .image-list__image.is-active {
  outline: 2px solid #47885e;
}
.is-quotation .section-quotation .image-upload .image-delete {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload .image-delete {
    margin-top: 24px;
  }
}
.is-quotation .section-quotation .image-upload .image-delete__button {
  padding: 0.2em 0.5em;
  text-decoration: underline;
  text-underline-offset: 5px;
  color: inherit;
}
.is-quotation .section-quotation .image-upload .image-delete__button.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload .image-delete__button {
    padding: 0.5em 1.5em 0.5em 1em;
  }
}
.is-quotation .section-quotation .image-upload .image-delete__button-inner {
  display: flex;
  align-items: center;
}
.is-quotation .section-quotation .image-upload .image-delete__icon {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload .image-delete__icon {
    width: 20px;
  }
}
.is-quotation .section-quotation .image-upload .image-delete__icon img {
  vertical-align: middle;
}
.is-quotation .section-quotation .image-upload input[type=file] {
  display: none;
}
.is-quotation .section-quotation .image-upload__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .image-upload__buttons {
    column-gap: 40px;
  }
}
.is-quotation .section-quotation .image-upload__buttons .image-button {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  min-height: 40px;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
}
.is-quotation .section-quotation .image-upload__buttons .image-button_upload {
  background-color: #47885e;
}
.is-quotation .section-quotation .image-upload__buttons .image-button.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.is-quotation .section-quotation .image-upload__buttons .image-button__icon {
  width: 13px;
  margin-right: 10px;
}
.is-quotation .section-quotation .image-upload__error {
  color: #a40000;
}
.is-quotation .section-quotation .pdf-form {
  margin-top: 18px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .pdf-form {
    margin-top: 50px;
  }
}
.is-quotation .section-quotation .pdf-form__button {
  color: #fff;
  background-color: #976fa6;
  padding: 0.5em;
  border-radius: 999px;
  min-height: 40px;
  width: 100%;
  max-width: 150px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .pdf-form__button {
    min-height: 50px;
    max-width: 250px;
  }
}
.is-quotation .section-quotation .pdf-form__button img {
  margin-right: 11px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .pdf-form__button img {
    width: 28px;
  }
}
.is-quotation .section-quotation .accordion-panel {
  height: 0;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.is-quotation .section-quotation .work-list {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 30px;
    padding-top: 97px;
  }
}
@media only screen and (min-width: 1024px) {
  .is-quotation .section-quotation .work-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.is-quotation .section-quotation .work-list__item + .work-list__item {
  margin-top: 15px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-list__item + .work-list__item {
    margin-top: 0;
  }
}
.is-quotation .section-quotation .work-detail-list__head {
  color: #fff;
  background-color: #669944;
  padding: 10px 19px;
  border-radius: 5px 5px 0 0;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__head {
    border-radius: 10px 10px 0 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__head {
    padding: 10.6px 19px;
  }
}
.is-quotation .section-quotation .work-detail-list__head_lawn {
  background-color: #9eb057;
}
.is-quotation .section-quotation .work-detail-list__head_felling {
  background-color: #998e44;
}
.is-quotation .section-quotation .work-detail-list__head_weeding {
  background-color: #70ad7a;
}
.is-quotation .section-quotation .work-detail-list__head_chemical {
  background-color: #5fb3af;
}
.is-quotation .section-quotation .work-detail-list__head_fertilization {
  background-color: #abb100;
}
.is-quotation .section-quotation .work-detail-list__head_others {
  background-color: #aaa;
}
.is-quotation .section-quotation .work-detail-list__body {
  border: 1px solid #e5e5e5;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__body {
    border-radius: 0 0 20px 20px;
  }
}
.is-quotation .section-quotation .work-detail-list__item + .work-detail-list__item {
  border-top: 1px solid #e5e5e5;
}
.is-quotation .section-quotation .work-detail-list__link {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 49px;
  padding: 12px 40px 13px 62px;
  background: #fafafa;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__link {
    min-height: 70px;
    padding: 1em 40px 1em 82px;
  }
}
.is-quotation .section-quotation .work-detail-list__link_options {
  padding: 12px 40px 13px 20px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__link_options {
    padding: 1em 40px 1em 20px;
  }
}
.is-quotation .section-quotation .work-detail-list__link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translate(0, -50%) rotate(45deg);
  z-index: 1;
  width: 12px;
  height: 12px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__link::after {
    right: 20px;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
.is-quotation .section-quotation .work-detail-list__link:hover::after {
  transform: translate(2px, -50%) rotate(45deg);
}
.is-quotation .section-quotation .work-detail-list__icon {
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 45px;
}
@media only screen and (min-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__icon {
    width: 60px;
    left: 41px;
  }
}
.is-quotation .section-quotation .work-detail-list__icon img {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .is-quotation .section-quotation .work-detail-list__title {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .section-content {
    padding-bottom: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .bg-wht-lg {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.is-quotation.detail .section-quotation-detail .bg-wht-lg__inner {
  background-color: #fff;
  padding-top: 22px;
  padding-right: 25px;
  padding-bottom: 190px;
  padding-left: 25px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .bg-wht-lg__inner {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 33px;
    padding-right: 60px;
    padding-bottom: 200px;
    padding-left: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .columns-2 {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .is-quotation.detail .section-quotation-detail .columns-2 > *:first-child {
    width: 55.5555%;
    min-width: 450px;
    margin-right: 20px;
  }
  .is-quotation.detail .section-quotation-detail .columns-2 > *:last-child {
    width: 40.7407%;
  }
}
.is-quotation.detail .section-quotation-detail .quotation-data {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .quotation-data {
    margin-left: -12px;
    margin-bottom: 35px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-heading {
  margin-bottom: 16px;
  padding: 0 15px;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-heading {
    margin-bottom: 30px;
    padding: 0 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-heading__icon {
  width: 82px;
  margin-right: 15px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-heading__icon {
    width: 120px;
    margin-right: 35px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-heading__icon img {
  width: 100%;
}
.is-quotation.detail .section-quotation-detail .detail-heading__body {
  padding-top: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-heading__body {
    padding-top: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-heading__label {
  padding: 2.5px 8.75px;
  background-color: #669944;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.9rem;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-heading__label {
    font-size: 1.4rem;
    padding: 7.4px 17px;
    border-radius: 6px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-heading__label_lawn {
  background-color: #9eb057;
}
.is-quotation.detail .section-quotation-detail .detail-heading__label_felling {
  background-color: #998e44;
}
.is-quotation.detail .section-quotation-detail .detail-heading__label_weeding {
  background-color: #70ad7a;
}
.is-quotation.detail .section-quotation-detail .detail-heading__label_chemical {
  background-color: #5fb3af;
}
.is-quotation.detail .section-quotation-detail .detail-heading__label_fertilization {
  background-color: #abb100;
}
.is-quotation.detail .section-quotation-detail .detail-heading__title {
  line-height: 1.25;
  font-size: 2rem;
  margin-top: 13px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-heading__title {
    font-size: 3rem;
    margin-top: 10px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-address {
  padding: 0 10px;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-address {
    padding: 0;
    margin-bottom: 40px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-address__field {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-address__field {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
}
.is-quotation.detail .section-quotation-detail .detail-config__head {
  background-color: #eef3e3;
  padding: 20px 17px 0;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__head {
    padding: 30px 30px 0;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__img {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.is-quotation.detail .section-quotation-detail .detail-config__body {
  background-color: #eef3e3;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 31px 20px 23px;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__body {
    padding: 30px 42px 28px 30px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__defi {
  display: flex;
  align-items: baseline;
}
.is-quotation.detail .section-quotation-detail .detail-config__term {
  font-size: 1.1rem;
  margin-right: 6px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__term {
    font-size: 1.4rem;
    margin-right: 11px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__term label {
  height: 100%;
  display: flex;
  align-items: center;
}
.is-quotation.detail .section-quotation-detail .detail-config__desc {
  position: relative;
}
.is-quotation.detail .section-quotation-detail .detail-config__desc .error {
  color: #a40000;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__desc .error {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__desc .error {
    font-size: 1.2rem;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__desc input {
  font-size: 1.5rem;
  background-color: #fff;
  text-align: center;
  padding: 0 0.5rem;
  height: 4rem;
  width: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__desc input {
    font-size: 1.8rem;
    height: 7rem;
    width: 7rem;
    border-radius: 4px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__desc select {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  color: inherit;
  height: 3.5rem;
  width: 125px;
  border-radius: 5px;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  padding: 0 24px 0 12px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__desc select {
    border: none;
    height: 5rem;
    width: 160px;
    border-radius: 6px;
    padding: 0 34px 0 16px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__desc .triangle {
  width: 8px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 14px;
  z-index: 2;
  transform: translate(0, -50%);
  background-color: #669944;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__desc .unit {
    font-size: 1.5rem;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__num-wrap {
  margin-right: 20px;
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__others-wrap {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi {
    margin-top: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__others-wrap .detail-config__defi {
  padding-left: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__others-wrap .detail-config__defi {
    padding-left: 0;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__others-wrap .detail-config__term {
  margin-right: 13px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__select {
    margin-right: 39px;
    margin-bottom: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config .dispose-btn {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #eef3e3;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config .dispose-btn {
    width: 60px;
    height: 30px;
    border-radius: 15px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config .dispose-btn__toggle {
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config .dispose-btn__toggle {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    width: 26px;
    height: 26px;
    top: 2px;
    left: 2px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__dispose {
  margin-top: 16px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose {
    margin-top: 7px;
    margin-bottom: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__dispose label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose label {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose label {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose .dispose-btn {
    margin-left: 13px;
  }
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose .dispose-btn {
    margin-left: 10px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__dispose input {
  display: none;
}
.is-quotation.detail .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn {
  background-color: #669944;
}
.is-quotation.detail .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle {
  transform: translateX(20px);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle {
    transform: translateX(30px);
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__bottom {
  background-color: #47885e;
  color: #fff;
  padding: 10px 10px 15px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__bottom {
    padding: 25px 30px 23px;
  }
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__bottom-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  .is-quotation.detail .section-quotation-detail .detail-config__bottom-inner > * {
    margin-bottom: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__root-param-wrap {
    font-size: 1rem;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param {
  display: flex;
  justify-content: center;
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param_edit {
  display: none;
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__root-param_edit {
    padding-top: 7px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param_edit.is-active {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__root-param_disable {
    margin-bottom: -5px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param_disable.is-hide {
  display: none;
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param .detail-config__defi + .detail-config__defi {
  margin-left: 10px;
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param_edit .detail-config__defi + .detail-config__defi {
  margin-left: 22px;
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param input {
  margin-left: 4px;
}
.is-quotation.detail .section-quotation-detail .detail-config__root-param input[id=unit-price] {
  width: 80px;
  margin-right: 10px;
}
.is-quotation.detail .section-quotation-detail .detail-config__btn-wrap {
  margin-top: 12px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__btn-wrap {
    margin-top: 0;
    margin-left: 20px;
    width: 160px;
    display: flex;
    align-items: center;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__btn {
  border-color: #fff;
  font-size: 1.1rem;
  width: 100%;
  max-width: 100px;
  padding: 6.6px 10px;
  border-radius: 17.5px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-config__btn {
    font-size: 1.6rem;
    max-width: 160px;
    padding: 8.6px 15px;
    border-radius: 25px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-config__btn.is-active {
  border-color: #000;
  background-color: #000;
}
.is-quotation.detail .section-quotation-detail .detail-config__btn.inactive {
  background: #aaa;
  pointer-events: none;
}
.is-quotation.detail .section-quotation-detail .title-sec {
  text-align: center;
  line-height: 1.25;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .title-sec {
    text-align: left;
  }
}
.is-quotation.detail .section-quotation-detail .label {
  padding: 9px 14.5px;
  border-radius: 15px;
  background-color: #e5e5e5;
  color: #a6a6a6;
  line-height: 1;
  display: inline-block;
  position: relative;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .label {
    padding: 12px 22px;
    border-radius: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .label__circle {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: scale(0, 0);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .label__circle {
    width: 30px;
    height: 30px;
  }
}
.is-quotation.detail .section-quotation-detail .label__check {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 9px;
  height: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .label__check {
    width: 15px;
    height: 7px;
  }
}
.is-quotation.detail .section-quotation-detail .label.is-active {
  background-color: #47885e;
  color: #fff;
  padding-left: 29.5px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .label.is-active {
    padding-left: 44px;
  }
}
.is-quotation.detail .section-quotation-detail .label.is-active .label__circle {
  transform: scale(1, 1);
}
.is-quotation.detail .section-quotation-detail .label.is-active .label__check {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.is-quotation.detail .section-quotation-detail .add-label-list {
  margin-bottom: 28px;
}
.is-quotation.detail .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item {
  margin-top: 28px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item {
    margin-top: 50px;
  }
}
.is-quotation.detail .section-quotation-detail .add-label__heading {
  margin-bottom: 13px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-label__heading {
    margin-bottom: 25px;
  }
}
.is-quotation.detail .section-quotation-detail .add-label__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -10px;
  margin-bottom: -10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-label__list {
    justify-content: left;
    margin-bottom: -20px;
  }
}
.is-quotation.detail .section-quotation-detail .add-label__item {
  margin-right: 10px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-label__item {
    margin-bottom: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .add-note {
  margin-bottom: 17px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-note {
    margin-bottom: 0;
  }
}
.is-quotation.detail .section-quotation-detail .add-note__heading {
  margin-bottom: 13px;
}
.is-quotation.detail .section-quotation-detail .add-note__field {
  height: auto;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .add-note__field {
    height: 170px;
  }
}
.is-quotation.detail .section-quotation-detail .caution {
  font-weight: normal;
  font-size: 1.1rem;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .caution {
    font-size: 1.4rem;
    padding-top: 10px;
  }
}
.is-quotation.detail .section-quotation-detail .back-btn {
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 1;
  display: none;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .back-btn {
    right: 60px;
    display: block;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 10px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total {
    padding: 0;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__inner {
  background-color: #262626;
  padding: 22.5px 20px 22.5px 29px;
  color: #fff;
  border-radius: 5px;
  box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total__inner {
    padding: 25px;
    border-radius: 0;
    box-shadow: none;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total__container {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__price {
  font-size: 3rem;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total__price {
    font-size: 3.6rem;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__price::before {
  content: "合計";
  font-size: 1.2rem;
  margin-right: 11px;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total__price::before {
    font-size: 1.8rem;
    margin-right: 20px;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__price::after {
  content: "円";
  font-size: 1.8rem;
}
.is-quotation.detail .section-quotation-detail .detail-total__btn {
  background-color: #47885e;
  color: #fff;
  padding: 6.7px 10px;
  border-radius: 3px;
  width: 100%;
  max-width: 110px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-quotation.detail .section-quotation-detail .detail-total__btn {
    font-size: 1.8rem;
    border-radius: 6px;
    max-width: 220px;
    padding: 18.8px 1em;
  }
}
.is-quotation.detail .section-quotation-detail .detail-total__btn.inactive {
  background: #aaa;
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .section-content,
.is-edit .section-quotation-detail .section-content {
    padding-bottom: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .bg-wht-lg,
.is-edit .section-quotation-detail .bg-wht-lg {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.is-add .section-quotation-detail .bg-wht-lg__inner,
.is-edit .section-quotation-detail .bg-wht-lg__inner {
  background-color: #fff;
  padding-top: 22px;
  padding-right: 25px;
  padding-bottom: 190px;
  padding-left: 25px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .bg-wht-lg__inner,
.is-edit .section-quotation-detail .bg-wht-lg__inner {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 33px;
    padding-right: 60px;
    padding-bottom: 200px;
    padding-left: 60px;
  }
}
.is-add .section-quotation-detail .group,
.is-edit .section-quotation-detail .group {
  padding: 0 10px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .group,
.is-edit .section-quotation-detail .group {
    padding: 0;
    margin-bottom: 32px;
  }
}
.is-add .section-quotation-detail .group__inner,
.is-edit .section-quotation-detail .group__inner {
  position: relative;
  width: 150px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .group__inner,
.is-edit .section-quotation-detail .group__inner {
    width: 180px;
  }
}
.is-add .section-quotation-detail .group select,
.is-edit .section-quotation-detail .group select {
  background-color: #262626;
  color: #fff;
  height: 3.5rem;
  width: 100%;
  border-radius: 5px;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  padding: 0 24px 0 12px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .group select,
.is-edit .section-quotation-detail .group select {
    border: none;
    height: 5rem;
    border-radius: 6px;
    padding: 0 34px 0 16px;
  }
}
.is-add .section-quotation-detail .group .triangle,
.is-edit .section-quotation-detail .group .triangle {
  width: 8px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 14px;
  z-index: 2;
  transform: translate(0, -50%);
  background-color: #669944;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .columns-2,
.is-edit .section-quotation-detail .columns-2 {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .is-add .section-quotation-detail .columns-2 > *:first-child,
.is-edit .section-quotation-detail .columns-2 > *:first-child {
    width: 55.5555%;
    min-width: 450px;
    margin-right: 20px;
  }
  .is-add .section-quotation-detail .columns-2 > *:last-child,
.is-edit .section-quotation-detail .columns-2 > *:last-child {
    width: 40.7407%;
  }
}
.is-add .section-quotation-detail .quotation-data,
.is-edit .section-quotation-detail .quotation-data {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .quotation-data,
.is-edit .section-quotation-detail .quotation-data {
    margin-left: -12px;
    margin-bottom: 35px;
  }
}
.is-add .section-quotation-detail .detail-heading,
.is-edit .section-quotation-detail .detail-heading {
  margin-bottom: 16px;
  padding: 0 15px;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-heading,
.is-edit .section-quotation-detail .detail-heading {
    margin-bottom: 30px;
    padding: 0 20px;
  }
}
.is-add .section-quotation-detail .detail-heading__icon,
.is-edit .section-quotation-detail .detail-heading__icon {
  width: 82px;
  margin-right: 15px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-heading__icon,
.is-edit .section-quotation-detail .detail-heading__icon {
    width: 120px;
    margin-right: 35px;
  }
}
.is-add .section-quotation-detail .detail-heading__icon img,
.is-edit .section-quotation-detail .detail-heading__icon img {
  width: 100%;
}
.is-add .section-quotation-detail .detail-heading__body,
.is-edit .section-quotation-detail .detail-heading__body {
  padding-top: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-heading__body,
.is-edit .section-quotation-detail .detail-heading__body {
    padding-top: 20px;
  }
}
.is-add .section-quotation-detail .detail-heading__label,
.is-edit .section-quotation-detail .detail-heading__label {
  padding: 2.5px 8.75px;
  background-color: #669944;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  font-size: 0.9rem;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-heading__label,
.is-edit .section-quotation-detail .detail-heading__label {
    font-size: 1.4rem;
    padding: 7.4px 17px;
    border-radius: 6px;
  }
}
.is-add .section-quotation-detail .detail-heading__label_lawn,
.is-edit .section-quotation-detail .detail-heading__label_lawn {
  background-color: #9eb057;
}
.is-add .section-quotation-detail .detail-heading__label_felling,
.is-edit .section-quotation-detail .detail-heading__label_felling {
  background-color: #998e44;
}
.is-add .section-quotation-detail .detail-heading__label_weeding,
.is-edit .section-quotation-detail .detail-heading__label_weeding {
  background-color: #70ad7a;
}
.is-add .section-quotation-detail .detail-heading__label_chemical,
.is-edit .section-quotation-detail .detail-heading__label_chemical {
  background-color: #5fb3af;
}
.is-add .section-quotation-detail .detail-heading__label_fertilization,
.is-edit .section-quotation-detail .detail-heading__label_fertilization {
  background-color: #abb100;
}
.is-add .section-quotation-detail .detail-heading__title,
.is-edit .section-quotation-detail .detail-heading__title {
  line-height: 1.25;
  font-size: 2rem;
  margin-top: 13px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-heading__title,
.is-edit .section-quotation-detail .detail-heading__title {
    font-size: 3rem;
    margin-top: 10px;
  }
}
.is-add .section-quotation-detail .detail-address,
.is-edit .section-quotation-detail .detail-address {
  padding: 0 10px;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-address,
.is-edit .section-quotation-detail .detail-address {
    padding: 0;
    margin-bottom: 40px;
  }
}
.is-add .section-quotation-detail .detail-address__field,
.is-edit .section-quotation-detail .detail-address__field {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-address__field,
.is-edit .section-quotation-detail .detail-address__field {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.is-add .section-quotation-detail .detail-config,
.is-edit .section-quotation-detail .detail-config {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
}
.is-add .section-quotation-detail .detail-config_single,
.is-edit .section-quotation-detail .detail-config_single {
  display: none;
}
.is-add .section-quotation-detail .detail-config__head,
.is-edit .section-quotation-detail .detail-config__head {
  background-color: #eef3e3;
  padding: 20px 17px 0;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__head,
.is-edit .section-quotation-detail .detail-config__head {
    padding: 30px 30px 0;
  }
}
.is-add .section-quotation-detail .detail-config__img,
.is-edit .section-quotation-detail .detail-config__img {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.is-add .section-quotation-detail .detail-config__body,
.is-edit .section-quotation-detail .detail-config__body {
  background-color: #eef3e3;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 31px 20px 23px;
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.is-add .section-quotation-detail .detail-config__body > *,
.is-edit .section-quotation-detail .detail-config__body > * {
  width: 50%;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__body,
.is-edit .section-quotation-detail .detail-config__body {
    padding: 30px 42px 28px 30px;
    min-height: 150px;
  }
}
.is-add .section-quotation-detail .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__defi {
  display: flex;
  align-items: baseline;
}
.is-add .section-quotation-detail .detail-config__term,
.is-edit .section-quotation-detail .detail-config__term {
  font-size: 1.1rem;
  margin-right: 6px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__term,
.is-edit .section-quotation-detail .detail-config__term {
    font-size: 1.4rem;
    margin-right: 11px;
  }
}
.is-add .section-quotation-detail .detail-config__term label,
.is-edit .section-quotation-detail .detail-config__term label {
  height: 100%;
  display: flex;
  align-items: center;
}
.is-add .section-quotation-detail .detail-config__desc,
.is-edit .section-quotation-detail .detail-config__desc {
  position: relative;
}
.is-add .section-quotation-detail .detail-config__desc.flex,
.is-edit .section-quotation-detail .detail-config__desc.flex {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc.flex input,
.is-edit .section-quotation-detail .detail-config__desc.flex input {
    height: 5rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc.flex input,
.is-edit .section-quotation-detail .detail-config__desc.flex input {
    height: 3.5rem;
  }
}
.is-add .section-quotation-detail .detail-config__desc input,
.is-edit .section-quotation-detail .detail-config__desc input {
  font-size: 1.5rem;
  background-color: #fff;
  text-align: center;
  padding: 0 0.5rem;
  height: 3.5rem;
  width: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc input,
.is-edit .section-quotation-detail .detail-config__desc input {
    font-size: 1.8rem;
    height: 5rem;
    width: 7rem;
    border-radius: 4px;
  }
}
.is-add .section-quotation-detail .detail-config__desc input.fullwide,
.is-edit .section-quotation-detail .detail-config__desc input.fullwide {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc input.fullwide,
.is-edit .section-quotation-detail .detail-config__desc input.fullwide {
    height: 5rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc input.fullwide,
.is-edit .section-quotation-detail .detail-config__desc input.fullwide {
    height: 3.5rem;
  }
}
.is-add .section-quotation-detail .detail-config__desc input.text,
.is-edit .section-quotation-detail .detail-config__desc input.text {
  text-align: left;
  padding: 0 1em;
}
.is-add .section-quotation-detail .detail-config__desc input:disabled,
.is-edit .section-quotation-detail .detail-config__desc input:disabled {
  background: #ddd;
}
.is-add .section-quotation-detail .detail-config__desc select,
.is-edit .section-quotation-detail .detail-config__desc select {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  color: inherit;
  height: 3.5rem;
  min-width: 60px;
  border-radius: 5px;
  padding: 0 20px 0 12px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc select,
.is-edit .section-quotation-detail .detail-config__desc select {
    border: none;
    height: 5rem;
    min-width: 80px;
    border-radius: 6px;
    padding: 0 30px 0 16px;
  }
}
.is-add .section-quotation-detail .detail-config__desc select.coefficient,
.is-edit .section-quotation-detail .detail-config__desc select.coefficient {
  color: #000;
  font-size: 1.2rem;
  min-width: 60px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc select.coefficient,
.is-edit .section-quotation-detail .detail-config__desc select.coefficient {
    font-size: 1.6rem;
    min-width: 80px;
  }
}
.is-add .section-quotation-detail .detail-config__desc select.fullwide,
.is-edit .section-quotation-detail .detail-config__desc select.fullwide {
  width: 100%;
}
.is-add .section-quotation-detail .detail-config__desc .triangle,
.is-edit .section-quotation-detail .detail-config__desc .triangle {
  width: 8px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 24px;
  z-index: 2;
  transform: translate(0, -50%);
  background-color: #669944;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc .triangle,
.is-edit .section-quotation-detail .detail-config__desc .triangle {
    right: 32px;
  }
}
.is-add .section-quotation-detail .detail-config__desc select[name=number] + .triangle,
.is-add .section-quotation-detail .detail-config__desc select[name=length] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=number] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=length] + .triangle {
  right: 32px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc select[name=number] + .triangle,
.is-add .section-quotation-detail .detail-config__desc select[name=length] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=number] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=length] + .triangle {
    right: 38px;
  }
}
.is-add .section-quotation-detail .detail-config__desc select[name=premium] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=premium] + .triangle {
  right: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__desc select[name=premium] + .triangle,
.is-edit .section-quotation-detail .detail-config__desc select[name=premium] + .triangle {
    right: 18px;
  }
}
.is-add .section-quotation-detail .detail-config__desc .coefficient + .triangle,
.is-edit .section-quotation-detail .detail-config__desc .coefficient + .triangle {
  right: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__num-wrap,
.is-edit .section-quotation-detail .detail-config__num-wrap {
    display: flex;
    justify-content: center;
  }
}
.is-add .section-quotation-detail .detail-config__num-wrap .detail-config__term,
.is-edit .section-quotation-detail .detail-config__num-wrap .detail-config__term {
  margin-right: 16px;
}
.is-add .section-quotation-detail .detail-config__others-wrap,
.is-edit .section-quotation-detail .detail-config__others-wrap {
  padding-left: 10px;
  margin-top: -8px;
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi {
  margin-top: 8px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__others-wrap,
.is-edit .section-quotation-detail .detail-config__others-wrap {
    padding-left: 40px;
    margin-top: -20px;
  }
  .is-add .section-quotation-detail .detail-config__others-wrap > *,
.is-edit .section-quotation-detail .detail-config__others-wrap > * {
    margin-top: 20px !important;
  }
}
.is-add .section-quotation-detail .detail-config__others-wrap.single,
.is-edit .section-quotation-detail .detail-config__others-wrap.single {
  flex-grow: 1;
}
.is-add .section-quotation-detail .detail-config__others-wrap.single .detail-config__term,
.is-edit .section-quotation-detail .detail-config__others-wrap.single .detail-config__term {
  flex-shrink: 0;
}
.is-add .section-quotation-detail .detail-config__others-wrap.single .detail-config__select,
.is-edit .section-quotation-detail .detail-config__others-wrap.single .detail-config__select {
  margin-right: 0;
  flex-grow: 1;
}
.is-add .section-quotation-detail .detail-config__others-wrap.single .detail-config__desc,
.is-edit .section-quotation-detail .detail-config__others-wrap.single .detail-config__desc {
  flex-grow: 1;
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi + .detail-config__defi {
    margin-top: 20px;
  }
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap {
    margin-top: 20px;
  }
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .detail-config__defi + .detail-config__defi {
  margin-top: 0;
  margin-left: 2em;
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .detail-config__defi + .detail-config__defi {
    margin-top: 10px;
  }
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .unit,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__defi-wrap .unit {
  margin-left: 5px;
}
.is-add .section-quotation-detail .detail-config__others-wrap .detail-config__term,
.is-edit .section-quotation-detail .detail-config__others-wrap .detail-config__term {
  margin-right: 13px;
}
.is-add .section-quotation-detail .detail-config .dispose-btn,
.is-edit .section-quotation-detail .detail-config .dispose-btn {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #eef3e3;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config .dispose-btn,
.is-edit .section-quotation-detail .detail-config .dispose-btn {
    width: 60px;
    height: 30px;
    border-radius: 15px;
  }
}
.is-add .section-quotation-detail .detail-config .dispose-btn__toggle,
.is-edit .section-quotation-detail .detail-config .dispose-btn__toggle {
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config .dispose-btn__toggle,
.is-edit .section-quotation-detail .detail-config .dispose-btn__toggle {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    width: 26px;
    height: 26px;
    top: 2px;
    left: 2px;
  }
}
.is-add .section-quotation-detail .detail-config__dispose,
.is-edit .section-quotation-detail .detail-config__dispose {
  margin-top: 16px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose,
.is-edit .section-quotation-detail .detail-config__dispose {
    margin-top: 30px;
  }
}
.is-add .section-quotation-detail .detail-config__dispose label,
.is-edit .section-quotation-detail .detail-config__dispose label {
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose label,
.is-edit .section-quotation-detail .detail-config__dispose label {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose label,
.is-edit .section-quotation-detail .detail-config__dispose label {
    font-size: 1.1rem;
  }
}
.is-add .section-quotation-detail .detail-config__dispose label[disabled],
.is-edit .section-quotation-detail .detail-config__dispose label[disabled] {
  opacity: 0.7;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose .dispose-btn,
.is-edit .section-quotation-detail .detail-config__dispose .dispose-btn {
    margin-left: 13px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose .dispose-btn,
.is-edit .section-quotation-detail .detail-config__dispose .dispose-btn {
    margin-left: 10px;
  }
}
.is-add .section-quotation-detail .detail-config__dispose input,
.is-edit .section-quotation-detail .detail-config__dispose input {
  display: none;
}
.is-add .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn,
.is-edit .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn {
  background-color: #669944;
}
.is-add .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle,
.is-edit .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle {
  transform: translateX(20px);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle,
.is-edit .section-quotation-detail .detail-config__dispose input:checked + .dispose-btn .dispose-btn__toggle {
    transform: translateX(30px);
  }
}
.is-add .section-quotation-detail .detail-config__bottom,
.is-edit .section-quotation-detail .detail-config__bottom {
  background-color: #47885e;
  color: #fff;
  padding: 10px 10px 15px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__bottom,
.is-edit .section-quotation-detail .detail-config__bottom {
    padding: 25px 30px 23px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__bottom-inner,
.is-edit .section-quotation-detail .detail-config__bottom-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: -20px;
    margin-left: -30px;
  }
  .is-add .section-quotation-detail .detail-config__bottom-inner > *,
.is-edit .section-quotation-detail .detail-config__bottom-inner > * {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}
.is-add .section-quotation-detail .detail-config__root-param-wrap,
.is-edit .section-quotation-detail .detail-config__root-param-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__root-param-wrap,
.is-edit .section-quotation-detail .detail-config__root-param-wrap {
    font-size: 1rem;
  }
}
.is-add .section-quotation-detail .detail-config__root-param,
.is-edit .section-quotation-detail .detail-config__root-param {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.is-add .section-quotation-detail .detail-config__root-param_edit,
.is-edit .section-quotation-detail .detail-config__root-param_edit {
  display: none;
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__root-param_edit,
.is-edit .section-quotation-detail .detail-config__root-param_edit {
    padding-top: 7px;
  }
}
.is-add .section-quotation-detail .detail-config__root-param_edit.is-active,
.is-edit .section-quotation-detail .detail-config__root-param_edit.is-active {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail .detail-config__root-param_disable,
.is-edit .section-quotation-detail .detail-config__root-param_disable {
    margin-bottom: -5px;
  }
}
.is-add .section-quotation-detail .detail-config__root-param_disable.is-hide,
.is-edit .section-quotation-detail .detail-config__root-param_disable.is-hide {
  display: none;
}
.is-add .section-quotation-detail .detail-config__root-param .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__root-param .detail-config__defi + .detail-config__defi {
  margin-left: 10px;
}
.is-add .section-quotation-detail .detail-config__root-param_edit .detail-config__defi + .detail-config__defi,
.is-edit .section-quotation-detail .detail-config__root-param_edit .detail-config__defi + .detail-config__defi {
  margin-left: 22px;
}
.is-add .section-quotation-detail .detail-config__root-param input,
.is-edit .section-quotation-detail .detail-config__root-param input {
  margin-left: 4px;
}
.is-add .section-quotation-detail .detail-config__root-param input[id=unit-price],
.is-edit .section-quotation-detail .detail-config__root-param input[id=unit-price] {
  width: 80px;
  margin-right: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__root-param input[id=unit-price],
.is-edit .section-quotation-detail .detail-config__root-param input[id=unit-price] {
    width: 100px;
  }
}
.is-add .section-quotation-detail .detail-config__btn-wrap,
.is-edit .section-quotation-detail .detail-config__btn-wrap {
  margin-top: 12px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__btn-wrap,
.is-edit .section-quotation-detail .detail-config__btn-wrap {
    margin-top: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
}
.is-add .section-quotation-detail .detail-config__btn,
.is-edit .section-quotation-detail .detail-config__btn {
  border-color: #fff;
  font-size: 1.1rem;
  display: inline-block;
  width: auto;
  min-width: 100px;
  padding: 6.6px 12px;
  border-radius: 99px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-config__btn,
.is-edit .section-quotation-detail .detail-config__btn {
    font-size: 1.6rem;
    min-width: 160px;
    padding: 8.6px 15px;
  }
}
.is-add .section-quotation-detail .detail-config__btn.is-active,
.is-edit .section-quotation-detail .detail-config__btn.is-active {
  border-color: #000;
  background-color: #000;
}
.is-add .section-quotation-detail .detail-config__btn.inactive,
.is-edit .section-quotation-detail .detail-config__btn.inactive {
  background: #aaa;
  border-color: #aaa;
  pointer-events: none;
}
.is-add .section-quotation-detail .detail-config__btn.js-reset-btn,
.is-edit .section-quotation-detail .detail-config__btn.js-reset-btn {
  display: none;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  margin-right: 10px;
}
.is-add .section-quotation-detail .detail-config__btn.js-reset-btn.is-active,
.is-edit .section-quotation-detail .detail-config__btn.js-reset-btn.is-active {
  display: inline-block;
}
.is-add .section-quotation-detail .title-sec,
.is-edit .section-quotation-detail .title-sec {
  text-align: center;
  line-height: 1.25;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .title-sec,
.is-edit .section-quotation-detail .title-sec {
    text-align: left;
  }
}
.is-add .section-quotation-detail .btn,
.is-edit .section-quotation-detail .btn {
  padding: 9px 14.5px;
  border-radius: 15px;
  background-color: #e5e5e5;
  color: #a6a6a6;
  line-height: 1;
  display: inline-block;
  position: relative;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .btn,
.is-edit .section-quotation-detail .btn {
    padding: 12px 22px;
    border-radius: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .btn:hover,
.is-edit .section-quotation-detail .btn:hover {
    background-color: #47885e;
    color: #fff;
  }
}
.is-add .section-quotation-detail .label,
.is-edit .section-quotation-detail .label {
  padding: 9px 14.5px;
  border-radius: 15px;
  background-color: #e5e5e5;
  color: #a6a6a6;
  line-height: 1;
  display: inline-block;
  position: relative;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .label,
.is-edit .section-quotation-detail .label {
    padding: 12px 22px;
    border-radius: 20px;
  }
}
.is-add .section-quotation-detail .label__circle,
.is-edit .section-quotation-detail .label__circle {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: scale(0, 0);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .label__circle,
.is-edit .section-quotation-detail .label__circle {
    width: 30px;
    height: 30px;
  }
}
.is-add .section-quotation-detail .label__check,
.is-edit .section-quotation-detail .label__check {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 9px;
  height: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .label__check,
.is-edit .section-quotation-detail .label__check {
    width: 15px;
    height: 7px;
  }
}
.is-add .section-quotation-detail .label.is-active,
.is-edit .section-quotation-detail .label.is-active {
  background-color: #47885e;
  color: #fff;
  padding-left: 29.5px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .label.is-active,
.is-edit .section-quotation-detail .label.is-active {
    padding-left: 44px;
  }
}
.is-add .section-quotation-detail .label input:checked + .label__circle,
.is-edit .section-quotation-detail .label input:checked + .label__circle {
  transform: scale(1, 1);
}
.is-add .section-quotation-detail .label input:checked + .label__circle .label__check,
.is-edit .section-quotation-detail .label input:checked + .label__circle .label__check {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.is-add .section-quotation-detail .add-label-list,
.is-edit .section-quotation-detail .add-label-list {
  margin-bottom: 28px;
}
.is-add .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item,
.is-edit .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item {
  margin-top: 28px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item,
.is-edit .section-quotation-detail .add-label-list .add-label-list__item + .add-label-list__item {
    margin-top: 50px;
  }
}
.is-add .section-quotation-detail .add-label__heading,
.is-edit .section-quotation-detail .add-label__heading {
  margin-bottom: 13px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-label__heading,
.is-edit .section-quotation-detail .add-label__heading {
    margin-bottom: 25px;
  }
}
.is-add .section-quotation-detail .add-label__list,
.is-edit .section-quotation-detail .add-label__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -10px;
  margin-bottom: -10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-label__list,
.is-edit .section-quotation-detail .add-label__list {
    justify-content: left;
    margin-bottom: -20px;
  }
}
.is-add .section-quotation-detail .add-label__item,
.is-edit .section-quotation-detail .add-label__item {
  margin-right: 10px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-label__item,
.is-edit .section-quotation-detail .add-label__item {
    margin-bottom: 20px;
  }
}
.is-add .section-quotation-detail .add-note,
.is-edit .section-quotation-detail .add-note {
  margin-bottom: 17px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-note,
.is-edit .section-quotation-detail .add-note {
    margin-bottom: 0;
  }
}
.is-add .section-quotation-detail .add-note__item + .add-note__item,
.is-edit .section-quotation-detail .add-note__item + .add-note__item {
  margin-top: 24px;
}
.is-add .section-quotation-detail .add-note__heading,
.is-edit .section-quotation-detail .add-note__heading {
  margin-bottom: 13px;
}
.is-add .section-quotation-detail .add-note__field,
.is-edit .section-quotation-detail .add-note__field {
  height: auto;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .add-note__field,
.is-edit .section-quotation-detail .add-note__field {
    height: 170px;
  }
}
.is-add .section-quotation-detail .caution,
.is-edit .section-quotation-detail .caution {
  font-weight: normal;
  font-size: 1.1rem;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .caution,
.is-edit .section-quotation-detail .caution {
    font-size: 1.4rem;
    padding-top: 10px;
  }
}
.is-add .section-quotation-detail .back-btn,
.is-edit .section-quotation-detail .back-btn {
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 1;
  display: none;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .back-btn,
.is-edit .section-quotation-detail .back-btn {
    right: 60px;
    display: block;
  }
}
.is-add .section-quotation-detail .detail-total,
.is-edit .section-quotation-detail .detail-total {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 10px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total,
.is-edit .section-quotation-detail .detail-total {
    padding: 0;
  }
}
.is-add .section-quotation-detail .detail-total__inner,
.is-edit .section-quotation-detail .detail-total__inner {
  background-color: #262626;
  padding: 22.5px 20px 22.5px 29px;
  color: #fff;
  border-radius: 5px;
  box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total__inner,
.is-edit .section-quotation-detail .detail-total__inner {
    padding: 25px;
    border-radius: 0;
    box-shadow: none;
  }
}
.is-add .section-quotation-detail .detail-total__container,
.is-edit .section-quotation-detail .detail-total__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total__container,
.is-edit .section-quotation-detail .detail-total__container {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
  }
}
.is-add .section-quotation-detail .detail-total__price,
.is-edit .section-quotation-detail .detail-total__price {
  font-size: 3rem;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total__price,
.is-edit .section-quotation-detail .detail-total__price {
    font-size: 3.6rem;
  }
}
.is-add .section-quotation-detail .detail-total__price::before,
.is-edit .section-quotation-detail .detail-total__price::before {
  content: "合計";
  font-size: 1.2rem;
  margin-right: 11px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total__price::before,
.is-edit .section-quotation-detail .detail-total__price::before {
    font-size: 1.8rem;
    margin-right: 20px;
  }
}
.is-add .section-quotation-detail .detail-total__price::after,
.is-edit .section-quotation-detail .detail-total__price::after {
  content: "円";
  font-size: 1.8rem;
}
.is-add .section-quotation-detail .detail-total__price_coef::before,
.is-edit .section-quotation-detail .detail-total__price_coef::before {
  content: "係数";
}
.is-add .section-quotation-detail .detail-total__price_coef::after,
.is-edit .section-quotation-detail .detail-total__price_coef::after {
  content: "";
}
.is-add .section-quotation-detail .detail-total__btn,
.is-edit .section-quotation-detail .detail-total__btn {
  background-color: #47885e;
  color: #fff;
  padding: 6.7px 10px;
  border-radius: 3px;
  width: 100%;
  max-width: 110px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail .detail-total__btn,
.is-edit .section-quotation-detail .detail-total__btn {
    font-size: 1.8rem;
    border-radius: 6px;
    max-width: 220px;
    padding: 18.8px 1em;
  }
}
.is-add .section-quotation-detail .detail-total__btn.inactive,
.is-edit .section-quotation-detail .detail-total__btn.inactive {
  background: #aaa;
  pointer-events: none;
}
.is-add .section-quotation-detail .check-list,
.is-edit .section-quotation-detail .check-list {
  margin-bottom: 50px;
}
.is-add .section-quotation-detail .check-list__item + .check-list__item,
.is-edit .section-quotation-detail .check-list__item + .check-list__item {
  margin-top: 10px;
}
.is-add .section-quotation-detail .check-list__item input[type=checkbox],
.is-edit .section-quotation-detail .check-list__item input[type=checkbox] {
  display: none;
}
.is-add .section-quotation-detail .check-list__item input[type=checkbox] + span,
.is-edit .section-quotation-detail .check-list__item input[type=checkbox] + span {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.2em 0;
  padding: 0 0 0 1.7em;
  position: relative;
}
.is-add .section-quotation-detail .check-list__item input[type=checkbox] + span::before,
.is-edit .section-quotation-detail .check-list__item input[type=checkbox] + span::before {
  background: #fff;
  border: 1px solid #dadada;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1em;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
.is-add .section-quotation-detail .check-list__item input[type=checkbox] + span::after,
.is-edit .section-quotation-detail .check-list__item input[type=checkbox] + span::after {
  border-bottom: 3px solid #47885e;
  border-left: 3px solid #47885e;
  content: "";
  display: block;
  height: 0.6em;
  left: 0;
  margin-top: -0.2em;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.3s ease 0s;
  width: 1em;
}
.is-add .section-quotation-detail .check-list__item input[type=checkbox]:checked + span::after,
.is-edit .section-quotation-detail .check-list__item input[type=checkbox]:checked + span::after {
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .bg-wht-lg__inner,
.is-edit .section-quotation-detail.option .bg-wht-lg__inner {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .bg-wht-lg__inner,
.is-edit .section-quotation-detail.option .bg-wht-lg__inner {
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .columns-2,
.is-edit .section-quotation-detail.option .columns-2 {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .is-add .section-quotation-detail.option .columns-2 > *:first-child,
.is-edit .section-quotation-detail.option .columns-2 > *:first-child {
    width: 50%;
    min-width: auto;
    margin-right: 20px;
  }
  .is-add .section-quotation-detail.option .columns-2 > *:last-child,
.is-edit .section-quotation-detail.option .columns-2 > *:last-child {
    width: 50%;
  }
}
.is-add .section-quotation-detail.option .validation-toggle-label,
.is-edit .section-quotation-detail.option .validation-toggle-label {
  display: inline-flex;
  align-items: center;
}
.is-add .section-quotation-detail.option .validation-toggle,
.is-edit .section-quotation-detail.option .validation-toggle {
  display: none;
}
.is-add .section-quotation-detail.option .validation-toggle + span,
.is-edit .section-quotation-detail.option .validation-toggle + span {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.3em 0;
  padding: 0 0 0 1.2em;
  position: relative;
}
.is-add .section-quotation-detail.option .validation-toggle + span::before,
.is-edit .section-quotation-detail.option .validation-toggle + span::before {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  content: "";
  display: block;
  height: 1em;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
.is-add .section-quotation-detail.option .validation-toggle + span::after,
.is-edit .section-quotation-detail.option .validation-toggle + span::after {
  border-bottom: 3px solid #47885e;
  border-left: 3px solid #47885e;
  content: "";
  display: block;
  height: 0.6em;
  left: 0;
  margin-top: -0.2em;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.3s ease 0s;
  width: 1em;
}
.is-add .section-quotation-detail.option .validation-toggle:checked + span::after,
.is-edit .section-quotation-detail.option .validation-toggle:checked + span::after {
  opacity: 1;
}
.is-add .section-quotation-detail.option .detail-heading .inactive,
.is-edit .section-quotation-detail.option .detail-heading .inactive {
  background-color: #aaa;
}
.is-add .section-quotation-detail.option .detail-config,
.is-edit .section-quotation-detail.option .detail-config {
  background-color: #eef3e3;
}
.is-add .section-quotation-detail.option .detail-config__title,
.is-edit .section-quotation-detail.option .detail-config__title {
  font-size: 1.6rem;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__title,
.is-edit .section-quotation-detail.option .detail-config__title {
    font-size: 2rem;
  }
}
.is-add .section-quotation-detail.option .detail-config__body,
.is-edit .section-quotation-detail.option .detail-config__body {
  display: block;
  border-bottom: none;
  padding: 6px 17px 23px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__body,
.is-edit .section-quotation-detail.option .detail-config__body {
    padding: 10px 30px 30px;
  }
}
.is-add .section-quotation-detail.option .detail-config__desc input,
.is-edit .section-quotation-detail.option .detail-config__desc input {
  width: 100%;
  text-align: left;
  padding-left: 1em;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__desc input,
.is-edit .section-quotation-detail.option .detail-config__desc input {
    height: 5rem;
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__desc input,
.is-edit .section-quotation-detail.option .detail-config__desc input {
    height: 3.5rem;
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__term,
.is-edit .section-quotation-detail.option .detail-config__term {
    margin-left: 10px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__term,
.is-edit .section-quotation-detail.option .detail-config__term {
    margin-left: 2.5px;
  }
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__defi:not(:first-child),
.is-edit .section-quotation-detail.option .detail-config__defi:not(:first-child) {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config__defi:not(:first-child),
.is-edit .section-quotation-detail.option .detail-config__defi:not(:first-child) {
    margin-left: 5px;
  }
}
.is-add .section-quotation-detail.option .detail-config-wrap,
.is-edit .section-quotation-detail.option .detail-config-wrap {
  width: auto;
}
.is-add .section-quotation-detail.option .special-vehicle .detail-config__defi,
.is-add .section-quotation-detail.option .disposal-cost .detail-config__defi,
.is-add .section-quotation-detail.option .other .detail-config__defi,
.is-edit .section-quotation-detail.option .special-vehicle .detail-config__defi,
.is-edit .section-quotation-detail.option .disposal-cost .detail-config__defi,
.is-edit .section-quotation-detail.option .other .detail-config__defi {
  width: 100%;
}
.is-add .section-quotation-detail.option .special-vehicle .detail-config__desc,
.is-add .section-quotation-detail.option .disposal-cost .detail-config__desc,
.is-add .section-quotation-detail.option .other .detail-config__desc,
.is-edit .section-quotation-detail.option .special-vehicle .detail-config__desc,
.is-edit .section-quotation-detail.option .disposal-cost .detail-config__desc,
.is-edit .section-quotation-detail.option .other .detail-config__desc {
  width: 100%;
}
.is-add .section-quotation-detail.option .road-permit .detail-config-wrap,
.is-edit .section-quotation-detail.option .road-permit .detail-config-wrap {
  justify-content: flex-end;
}
.is-add .section-quotation-detail.option .road-permit .detail-config__defi,
.is-edit .section-quotation-detail.option .road-permit .detail-config__defi {
  width: 50%;
}
.is-add .section-quotation-detail.option .road-permit .detail-config__term,
.is-edit .section-quotation-detail.option .road-permit .detail-config__term {
  white-space: nowrap;
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .road-permit .detail-config__term,
.is-edit .section-quotation-detail.option .road-permit .detail-config__term {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .road-permit .detail-config__term,
.is-edit .section-quotation-detail.option .road-permit .detail-config__term {
    margin-right: 5px;
  }
}
.is-add .section-quotation-detail.option .detail-config-wrap,
.is-edit .section-quotation-detail.option .detail-config-wrap {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config-wrap.detail-config-wrap:not(:first-child),
.is-edit .section-quotation-detail.option .detail-config-wrap.detail-config-wrap:not(:first-child) {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config-wrap.detail-config-wrap:not(:first-child),
.is-edit .section-quotation-detail.option .detail-config-wrap.detail-config-wrap:not(:first-child) {
    margin-top: 10px;
  }
}
.is-add .section-quotation-detail.option .discount .detail-config,
.is-edit .section-quotation-detail.option .discount .detail-config {
  color: #fff;
  background-color: #47885e;
}
.is-add .section-quotation-detail.option .discount .detail-config-wrap,
.is-edit .section-quotation-detail.option .discount .detail-config-wrap {
  justify-content: flex-end;
}
.is-add .section-quotation-detail.option .discount .detail-config__head,
.is-edit .section-quotation-detail.option .discount .detail-config__head {
  background-color: #47885e;
}
.is-add .section-quotation-detail.option .discount .detail-config__body,
.is-edit .section-quotation-detail.option .discount .detail-config__body {
  background-color: #47885e;
}
.is-add .section-quotation-detail.option .discount .detail-config__term,
.is-edit .section-quotation-detail.option .discount .detail-config__term {
  display: flex;
  align-items: center;
}
.is-add .section-quotation-detail.option .discount .detail-config__defi + span,
.is-edit .section-quotation-detail.option .discount .detail-config__defi + span {
  text-align: center;
  display: flex;
  align-items: center;
  width: 20%;
  font-size: 1rem;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .discount .detail-config__defi + span,
.is-edit .section-quotation-detail.option .discount .detail-config__defi + span {
    margin-left: 20px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .discount .detail-config__defi + span,
.is-edit .section-quotation-detail.option .discount .detail-config__defi + span {
    margin-left: 5px;
  }
}
.is-add .section-quotation-detail.option .discount .detail-config.premium-price,
.is-edit .section-quotation-detail.option .discount .detail-config.premium-price {
  background-color: #eef3e3;
  color: #000;
}
.is-add .section-quotation-detail.option .discount .detail-config.premium-price .detail-config__head,
.is-add .section-quotation-detail.option .discount .detail-config.premium-price .detail-config__body,
.is-edit .section-quotation-detail.option .discount .detail-config.premium-price .detail-config__head,
.is-edit .section-quotation-detail.option .discount .detail-config.premium-price .detail-config__body {
  background-color: #eef3e3;
}
.is-add .section-quotation-detail.option .discount .detail-config.premium-price .detail-config-wrap,
.is-edit .section-quotation-detail.option .discount .detail-config.premium-price .detail-config-wrap {
  justify-content: start;
}
.is-add .section-quotation-detail.option .detail-total__container,
.is-edit .section-quotation-detail.option .detail-total__container {
  align-items: flex-end;
}
.is-add .section-quotation-detail.option .detail-total__price,
.is-edit .section-quotation-detail.option .detail-total__price {
  font-size: 3rem;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price,
.is-edit .section-quotation-detail.option .detail-total__price {
    font-size: 3.6rem;
  }
}
.is-add .section-quotation-detail.option .detail-total__price::before,
.is-edit .section-quotation-detail.option .detail-total__price::before {
  content: "合計";
  font-size: 1.2rem;
  margin-right: 11px;
  width: 100%;
  margin-bottom: 0.5em;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price::before,
.is-edit .section-quotation-detail.option .detail-total__price::before {
    font-size: 1.8rem;
    margin-right: 20px;
  }
}
.is-add .section-quotation-detail.option .detail-total__price:not(:first-of-type),
.is-edit .section-quotation-detail.option .detail-total__price:not(:first-of-type) {
  margin-top: 0.5em;
}
.is-add .section-quotation-detail.option .detail-total__price.campaign::before, .is-add .section-quotation-detail.option .detail-total__price.member::before,
.is-edit .section-quotation-detail.option .detail-total__price.campaign::before,
.is-edit .section-quotation-detail.option .detail-total__price.member::before {
  content: "割引";
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price.campaign::before, .is-add .section-quotation-detail.option .detail-total__price.member::before,
.is-edit .section-quotation-detail.option .detail-total__price.campaign::before,
.is-edit .section-quotation-detail.option .detail-total__price.member::before {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price.campaign::before, .is-add .section-quotation-detail.option .detail-total__price.member::before,
.is-edit .section-quotation-detail.option .detail-total__price.campaign::before,
.is-edit .section-quotation-detail.option .detail-total__price.member::before {
    font-size: 1.2rem;
  }
}
.is-add .section-quotation-detail.option .detail-total__price.campaign::after, .is-add .section-quotation-detail.option .detail-total__price.member::after,
.is-edit .section-quotation-detail.option .detail-total__price.campaign::after,
.is-edit .section-quotation-detail.option .detail-total__price.member::after {
  content: "%";
  font-size: 1.8rem;
}
.is-add .section-quotation-detail.option .detail-total__price.industry::before,
.is-edit .section-quotation-detail.option .detail-total__price.industry::before {
  content: "値引";
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price.industry::before,
.is-edit .section-quotation-detail.option .detail-total__price.industry::before {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-add .section-quotation-detail.option .detail-total__price.industry::before,
.is-edit .section-quotation-detail.option .detail-total__price.industry::before {
    font-size: 1.2rem;
  }
}
.is-add .section-quotation-detail.option .detail-config_calc,
.is-edit .section-quotation-detail.option .detail-config_calc {
  font-size: 1.6rem;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config_calc,
.is-edit .section-quotation-detail.option .detail-config_calc {
    font-size: 1.8rem;
  }
}
.is-add .section-quotation-detail.option .detail-config_calc .detail-config__body,
.is-edit .section-quotation-detail.option .detail-config_calc .detail-config__body {
  padding: 20px 17px 23px;
}
@media only screen and (min-width: 768px) {
  .is-add .section-quotation-detail.option .detail-config_calc .detail-config__body,
.is-edit .section-quotation-detail.option .detail-config_calc .detail-config__body {
    padding: 30px;
  }
}

.is-customer .btn-wrap a,
.is-customer .btn-wrap button {
  margin: auto;
}
@media only screen and (min-width: 768px) {
  .is-customer .section-customer {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer .section-customer {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
.is-customer .section-customer .headline__title {
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-customer .section-customer .headline__title {
    font-size: 3.6rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer .section-customer .headline__title {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .content {
    display: flex;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .content {
    margin-top: 25px;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .btn-wrap {
    width: 25%;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .btn-wrap .primary-btn {
    max-width: none;
    font-size: 1.8rem;
    padding: 2.25rem 1.5rem;
    border-radius: 10px;
  }
}
.is-customer.select .section-customer .customer {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer {
    width: 75%;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer {
    margin-top: 20px;
  }
}
.is-customer.select .section-customer .customer-settings {
  background: #c1d8ac;
  border-radius: 10px 10px 0 0;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-settings {
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-settings {
    padding: 25px 15px;
  }
}
.is-customer.select .section-customer .customer-index__search {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
  max-width: 500px;
  margin: auto;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-index__search {
    border-radius: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-index__search {
    border-radius: 2px;
  }
}
.is-customer.select .section-customer .customer-index__search .search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-index__search .search-icon {
    width: 55px;
    height: 50px;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-index__search .search-icon {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-index__search .search-icon img {
    width: 40%;
    height: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-index__search .search-icon img {
    width: 50%;
    height: 50%;
  }
}
.is-customer.select .section-customer .customer-index__search input {
  cursor: pointer;
  display: block;
  background: #fff;
  width: 100%;
  padding-right: 1em;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-index__search input {
    padding: 0.65em 0.5em;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-index__search input {
    padding: 0.75em 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-controller {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-controller {
    margin-top: 15px;
  }
}
.is-customer.select .section-customer .customer-controller-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  margin: auto;
}
.is-customer.select .section-customer .customer-controller .sort-list {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 0.75em 0;
  position: absolute;
  bottom: -10px;
  left: 0;
  transform: translateY(calc(100% - 20px));
  transition: opacity 400ms cubic-bezier(0.215, 0.61, 0.355, 1), visibility 400ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #fff;
  z-index: 1;
}
.is-customer.select .section-customer .customer-controller .sort-list.is-open {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(100%);
}
.is-customer.select .section-customer .customer-controller .sort-item {
  position: relative;
}
.is-customer.select .section-customer .customer-controller .sort-item:not(:first-of-type) {
  border-top: 1px solid #f6f6f6;
}
.is-customer.select .section-customer .customer-controller .sort-item.is-current::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-image: url(../img/customer/icon-check.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.is-customer.select .section-customer .customer-controller .sort-item button {
  color: #000;
  display: block;
  font-size: 1.5rem;
  padding: 0.4em 1.75em;
  padding-left: 2.25em;
}
.is-customer.select .section-customer .customer-controller .narrow {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  transform: translateY(calc(100% - 20px));
  transition: opacity 400ms cubic-bezier(0.215, 0.61, 0.355, 1), visibility 400ms cubic-bezier(0.215, 0.61, 0.355, 1), transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 15px;
  padding-bottom: 25px;
  background-color: #fafafa;
  z-index: 1;
}
.is-customer.select .section-customer .customer-controller .narrow.is-open {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(100%);
}
.is-customer.select .section-customer .customer-controller .narrow-inner {
  position: relative;
  padding-top: 10px;
}
.is-customer.select .section-customer .customer-controller .narrow-list {
  padding-left: 20px;
  padding-right: 40px;
}
.is-customer.select .section-customer .customer-controller .narrow-item {
  display: flex;
  align-items: center;
}
.is-customer.select .section-customer .customer-controller .narrow-item:not(:first-of-type) {
  margin-top: 15px;
}
.is-customer.select .section-customer .customer-controller .narrow__name {
  flex-shrink: 0;
  padding-right: 1em;
}
.is-customer.select .section-customer .customer-controller .narrow-select {
  position: relative;
  flex-grow: 1;
}
.is-customer.select .section-customer .customer-controller .narrow-select::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px calc(7px / 2) 0 calc(7px / 2);
  border-color: #486a00 transparent transparent transparent;
  pointer-events: none;
}
.is-customer.select .section-customer .customer-controller .narrow-select select {
  width: 100%;
  background: #fff;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 0.75em 1.25em;
  padding-right: 2em;
  color: inherit;
}
.is-customer.select .section-customer .customer-controller .narrow-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 0 10px;
}
.is-customer.select .section-customer .customer-controller .narrow-btns .btn {
  border-radius: 35px;
  margin: 0 5px;
  width: calc(50% - 10px);
  padding: 0.7em;
  color: inherit;
  text-align: center;
}
.is-customer.select .section-customer .customer-controller .narrow-btns .btn.clear {
  background: #fff;
  border: 1px solid #d9d9d9;
}
.is-customer.select .section-customer .customer-controller .narrow-btns .btn.submit {
  color: #fff;
  background: #262626;
  border: 1px solid #262626;
}
.is-customer.select .section-customer .customer-controller .narrow-close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.is-customer.select .section-customer .customer-controller .narrow-close::before, .is-customer.select .section-customer .customer-controller .narrow-close::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
}
.is-customer.select .section-customer .customer-controller .narrow-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.is-customer.select .section-customer .customer-controller .narrow-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.is-customer.select .section-customer .customer-sort, .is-customer.select .section-customer .customer-narrow {
  width: calc((100% - 15px) / 2);
}
.is-customer.select .section-customer .customer-btn {
  display: block;
  position: relative;
  cursor: pointer;
  background: #fff;
  border-radius: 50px;
  font-size: 1.5rem;
  padding: 0.55em 0.5em;
  width: 100%;
  max-width: 170px;
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-btn {
    transition: 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-btn:hover {
    color: #fff;
    background: #595959;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-btn:hover.sort-btn::before {
    background-image: url(../img/customer/icon-sort_wh.svg);
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-btn:hover.narrow-btn::before {
    background-image: url(../img/customer/icon-narrow_wh.svg);
  }
}
.is-customer.select .section-customer .customer-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-btn::before {
    left: 25px;
    width: 18px;
    height: 18px;
    transition: 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-btn::before {
    left: 17px;
    width: 15px;
    height: 15px;
  }
}
.is-customer.select .section-customer .customer-btn.sort-btn {
  padding-left: 10%;
  margin-left: auto;
}
.is-customer.select .section-customer .customer-btn.sort-btn::before {
  background-image: url(../img/customer/icon-sort.svg);
}
.is-customer.select .section-customer .customer-btn.narrow-btn {
  padding-left: 20%;
}
.is-customer.select .section-customer .customer-btn.narrow-btn::before {
  background-image: url(../img/customer/icon-narrow.svg);
}
.is-customer.select .section-customer .customer-btn.is-active {
  color: #fff;
  background: #262626;
}
.is-customer.select .section-customer .customer-btn.is-active.sort-btn::before {
  background-image: url(../img/customer/icon-sort_wh.svg);
}
.is-customer.select .section-customer .customer-btn.is-active.narrow-btn::before {
  background-image: url(../img/customer/icon-narrow_wh.svg);
}
.is-customer.select .section-customer .customer-result-item {
  line-height: 1;
  border-top: 1px solid #f6f6f6;
}
.is-customer.select .section-customer .customer-result-item a {
  position: relative;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-result-item a {
    padding: 2em 50px 1.5em;
    padding-right: 75px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-result-item a {
    padding: 2em 2em 1.5em;
    padding-right: 30px;
  }
}
.is-customer.select .section-customer .customer-result-item a::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid #030000;
  border-top: 2px solid #030000;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-result-item a::before {
    right: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-result-item a::before {
    right: 20px;
  }
}
.is-customer.select .section-customer .customer-result-item-inner {
  padding-left: 1em;
}
.is-customer.select .section-customer .customer-result__icon {
  flex-shrink: 0;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-result__icon {
    width: 30px;
    height: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-result__icon {
    width: 20px;
    height: 18px;
  }
}
.is-customer.select .section-customer .customer-result__icon img {
  width: auto;
  height: 100%;
}
.is-customer.select .section-customer .customer-result__company {
  margin-bottom: 5px;
}
.is-customer.select .section-customer .customer-result__name {
  word-break: break-all;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-result__name {
    font-size: 2.1rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-result__name {
    font-size: 1.8rem;
  }
}
.is-customer.select .section-customer .customer-result__name::after {
  content: "様";
  margin-left: 0.6rem;
  font-size: 1.2rem;
}
@media only screen and (min-width: 768px) {
  .is-customer.select .section-customer .customer-result__address {
    font-size: 1.2rem;
    margin-top: 0.75em;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.select .section-customer .customer-result__address {
    font-size: 1rem;
    margin-top: 0.5em;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .content, .is-customer.edit .section-customer .content {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .content, .is-customer.edit .section-customer .content {
    margin-top: 20px;
  }
}
.is-customer.add .section-customer .customer, .is-customer.edit .section-customer .customer {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer, .is-customer.edit .section-customer .customer {
    padding-top: 60px;
    padding-bottom: 45px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer, .is-customer.edit .section-customer .customer {
    padding-top: 45px;
    padding-bottom: 35px;
  }
}
.is-customer.add .section-customer .customer__back-btn, .is-customer.edit .section-customer .customer__back-btn {
  position: absolute;
  top: 0;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 1;
}
.is-customer.add .section-customer .customer-add-switch, .is-customer.edit .section-customer .customer-add-switch {
  display: flex;
  justify-content: center;
}
.is-customer.add .section-customer .customer-add-switch-type, .is-customer.edit .section-customer .customer-add-switch-type {
  padding: 0 15px;
}
.is-customer.add .section-customer .customer-add-switch-type input[type=radio], .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] {
  display: none;
}
.is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label {
  cursor: pointer;
  padding-left: 2.5em;
  position: relative;
}
.is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::before, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::before {
  content: "";
  display: block;
  background: #f6f6f6;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::before, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::before {
    width: 28px;
    height: 28px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::before, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::before {
    width: 20px;
    height: 20px;
  }
}
.is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::after, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::after {
  content: "";
  background: #8c8c8c;
  border: 1px solid transparent;
  border-radius: 50%;
  opacity: 0;
  padding: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::after, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::after {
    width: 14px;
    height: 14px;
    left: 7px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type input[type=radio] + label::after, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio] + label::after {
    width: 10px;
    height: 10px;
    left: 5px;
  }
}
.is-customer.add .section-customer .customer-add-switch-type input[type=radio]:checked + label::after, .is-customer.edit .section-customer .customer-add-switch-type input[type=radio]:checked + label::after {
  opacity: 1;
}
.is-customer.add .section-customer .customer-add-switch-type label, .is-customer.edit .section-customer .customer-add-switch-type label {
  display: flex;
  align-items: center;
}
.is-customer.add .section-customer .customer-add-switch-type .switch-icon, .is-customer.edit .section-customer .customer-add-switch-type .switch-icon {
  display: block;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type .switch-icon, .is-customer.edit .section-customer .customer-add-switch-type .switch-icon {
    width: 35px;
    height: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type .switch-icon, .is-customer.edit .section-customer .customer-add-switch-type .switch-icon {
    width: 30px;
    height: 25px;
  }
}
.is-customer.add .section-customer .customer-add-switch-type .switch-icon img, .is-customer.edit .section-customer .customer-add-switch-type .switch-icon img {
  height: 100%;
  width: auto;
}
.is-customer.add .section-customer .customer-add-switch-type .switch-name, .is-customer.edit .section-customer .customer-add-switch-type .switch-name {
  display: block;
  margin-left: 0.5em;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type .switch-name, .is-customer.edit .section-customer .customer-add-switch-type .switch-name {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-switch-type .switch-name, .is-customer.edit .section-customer .customer-add-switch-type .switch-name {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info, .is-customer.edit .section-customer .customer-add-info {
    margin-top: 50px;
    padding: 0 60px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info, .is-customer.edit .section-customer .customer-add-info {
    margin-top: 30px;
    padding: 0 35px;
  }
}
.is-customer.add .section-customer .customer-add-info-company, .is-customer.edit .section-customer .customer-add-info-company {
  margin-bottom: 50px;
  display: none;
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-company, .is-customer.edit .section-customer .customer-add-info-company {
    margin-bottom: 30px;
  }
}
.is-customer.add .section-customer .customer-add-info-company.is-show, .is-customer.edit .section-customer .customer-add-info-company.is-show {
  display: block;
}
.is-customer.add .section-customer .customer-add-info.corporation, .is-customer.edit .section-customer .customer-add-info.corporation {
  display: none;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-box, .is-customer.edit .section-customer .customer-add-info-box {
    display: flex;
  }
}
.is-customer.add .section-customer .customer-add-info-box:not(:first-of-type), .is-customer.edit .section-customer .customer-add-info-box:not(:first-of-type) {
  margin-top: 35px;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info__title, .is-customer.edit .section-customer .customer-add-info__title {
    font-size: 1.6rem;
    flex-shrink: 0;
    min-width: 200px;
    padding: 0.75em 0;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info__title, .is-customer.edit .section-customer .customer-add-info__title {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-inner, .is-customer.edit .section-customer .customer-add-info-inner {
    flex-grow: 1;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-inner, .is-customer.edit .section-customer .customer-add-info-inner {
    margin-top: 10px;
  }
}
.is-customer.add .section-customer .customer-add-info-inner.name .customer-add-info-field:not(:first-of-type), .is-customer.edit .section-customer .customer-add-info-inner.name .customer-add-info-field:not(:first-of-type) {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field:not(:first-of-type), .is-customer.edit .section-customer .customer-add-info-field:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field:not(:first-of-type), .is-customer.edit .section-customer .customer-add-info-field:not(:first-of-type) {
    margin-top: 10px;
  }
}
.is-customer.add .section-customer .customer-add-info-field-inner, .is-customer.edit .section-customer .customer-add-info-field-inner {
  position: relative;
}
.is-customer.add .section-customer .customer-add-info-field.flex, .is-customer.edit .section-customer .customer-add-info-field.flex {
  display: flex;
}
.is-customer.add .section-customer .customer-add-info-field .half, .is-customer.edit .section-customer .customer-add-info-field .half {
  width: calc(50% - 5px);
}
.is-customer.add .section-customer .customer-add-info-field .hyphen, .is-customer.edit .section-customer .customer-add-info-field .hyphen {
  display: block;
  padding-top: 0.7em;
  margin: 0 10px;
}
.is-customer.add .section-customer .customer-add-info-field input, .is-customer.edit .section-customer .customer-add-info-field input {
  width: 100%;
  background: #fafafa;
  padding: 0.7em 1.25em;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}
.is-customer.add .section-customer .customer-add-info-field input::placeholder, .is-customer.edit .section-customer .customer-add-info-field input::placeholder {
  color: #b3b3b3;
}
.is-customer.add .section-customer .customer-add-info-field input::-ms-input-placeholder, .is-customer.edit .section-customer .customer-add-info-field input::-ms-input-placeholder {
  color: #b3b3b3;
}
.is-customer.add .section-customer .customer-add-info-field input + input, .is-customer.edit .section-customer .customer-add-info-field input + input {
  margin-left: 10px;
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field input.js-address-zip, .is-customer.edit .section-customer .customer-add-info-field input.js-address-zip {
    width: 100%;
  }
}
.is-customer.add .section-customer .customer-add-info-field button, .is-customer.edit .section-customer .customer-add-info-field button {
  color: #fff;
  padding: 0.5em;
  background: #595959;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-left: 10px;
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field button, .is-customer.edit .section-customer .customer-add-info-field button {
    font-size: 1.2rem;
    min-width: 100px;
    transition: 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field button, .is-customer.edit .section-customer .customer-add-info-field button {
    font-size: 1rem;
    min-width: 75px;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.add .section-customer .customer-add-info-field button:hover, .is-customer.edit .section-customer .customer-add-info-field button:hover {
    background: #fff;
    color: #000;
  }
}
.is-customer.add .section-customer .customer-add-info-field .select-box, .is-customer.edit .section-customer .customer-add-info-field .select-box {
  position: relative;
}
.is-customer.add .section-customer .customer-add-info-field .select-box::before, .is-customer.edit .section-customer .customer-add-info-field .select-box::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px calc(7px / 2) 0 calc(7px / 2);
  border-color: #486a00 transparent transparent transparent;
  pointer-events: none;
}
.is-customer.add .section-customer .customer-add-info-field .select-box select, .is-customer.edit .section-customer .customer-add-info-field .select-box select {
  width: 100%;
  padding: 0.75em 1.25em;
  padding-right: 2.25em;
  box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: inherit;
}
.is-customer.add .section-customer .customer-add-info-field .notes, .is-customer.edit .section-customer .customer-add-info-field .notes {
  display: block;
  text-align: right;
  font-size: 1rem;
  margin-top: 0.25em;
  font-weight: 400;
}
.is-customer.add .section-customer .customer .btn-wrap, .is-customer.edit .section-customer .customer .btn-wrap {
  margin-top: 55px;
}
.is-customer.add .section-customer .customer .btn-wrap .primary-btn.inactive, .is-customer.edit .section-customer .customer .btn-wrap .primary-btn.inactive {
  background: #aaa;
  pointer-events: none;
}
.is-customer.add .section-customer .customer-delete, .is-customer.edit .section-customer .customer-delete {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
.is-customer.add .section-customer .customer-delete-button, .is-customer.edit .section-customer .customer-delete-button {
  padding: 0.2em 0.5em;
  text-decoration: underline;
  text-underline-offset: 5px;
  color: inherit;
}

.is-customer.top .section-customer {
  padding-top: 21px;
  padding-bottom: 19px;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer {
    padding-top: 43px;
    padding-bottom: 30px;
  }
}
.is-customer.top .section-customer .customer {
  display: flex;
}
.is-customer.top .section-customer .customer__img {
  width: 25px;
  margin-right: 16px;
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__img {
    width: 34px;
    margin-right: 22px;
  }
}
.is-customer.top .section-customer .customer__img img {
  width: 100%;
}
.is-customer.top .section-customer .customer__body {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__body {
    margin-right: 32px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .is-customer.top .section-customer .customer__body {
    margin-right: 15px;
    max-width: calc(100% - 63px);
  }
}
.is-customer.top .section-customer .customer__company {
  margin-bottom: 4px;
}
.is-customer.top .section-customer .customer__name {
  font-size: 3rem;
  line-height: 1;
  word-break: break-all;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__name {
    font-size: 4.2rem;
  }
}
.is-customer.top .section-customer .customer__name::after {
  content: "様";
  margin-left: 10px;
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__name::after {
    font-size: 2.1rem;
    margin-left: 15px;
  }
}
.is-customer.top .section-customer .customer__text {
  font-weight: normal;
  font-size: 0.9rem;
  margin-top: 13px;
  line-height: 1.7;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__text {
    font-size: 1.3rem;
    margin-top: 18px;
  }
}
.is-customer.top .section-customer .customer__btn-wrap {
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__btn-wrap {
    min-width: 154px;
  }
}
.is-customer.top .section-customer .customer__rounded-btn {
  background-color: #252626;
  max-width: 110px;
  font-size: 1.1rem;
  padding: 4px 15px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-customer .customer__rounded-btn {
    font-size: 1.5rem;
    padding: 5.5px 10px;
    max-width: 154px;
    border-radius: 21px;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    border-width: 2px;
  }
  .is-customer.top .section-customer .customer__rounded-btn:hover {
    background-color: #fff;
    border-color: #000;
  }
}
.is-customer.top .section-quotation {
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .content {
    display: flex;
  }
}
.is-customer.top .section-quotation .add-btn-wrap {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .add-btn-wrap {
    width: 300px;
    margin-right: 4.16667%;
  }
}
.is-customer.top .section-quotation .add-btn {
  color: #fff;
  background-color: #47885e;
  width: 100%;
  max-width: 235px;
  font-size: 1.5rem;
  border-radius: 5px;
  text-align: center;
  padding: 1.15rem 1.5rem;
  box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .add-btn {
    max-width: none;
    border: 2px solid transparent;
    padding: 2.18rem 1.5rem;
    border-radius: 10px;
    font-size: 1.8rem;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .is-customer.top .section-quotation .add-btn:hover {
    background-color: #fff;
    border-color: #47885e;
    color: #47885e;
  }
}
.is-customer.top .section-quotation .quotation-list {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation-list {
    margin-top: 0;
    width: 850px;
  }
}
.is-customer.top .section-quotation .quotation-list .quotation-list__item + .quotation-list__item {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation-list .quotation-list__item + .quotation-list__item {
    margin-top: 30px;
  }
}
.is-customer.top .section-quotation .quotation {
  padding: 14px 10px;
}
.is-customer.top .section-quotation .quotation_highlight {
  padding: 11.5px 7.5px;
  border: 2.5px solid #cd2;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation {
    padding: 28px 50px 31px;
  }
}
.is-customer.top .section-quotation .quotation__head {
  padding-bottom: 13px;
  padding-left: 8px;
  border-bottom: 1px solid #f6f6f6;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation__head {
    padding-bottom: 32px;
    padding-left: 0;
  }
}
.is-customer.top .section-quotation .quotation .quotation-data {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation .quotation-data {
    padding-top: 15px;
  }
}
.is-customer.top .section-quotation .quotation .quotation-data__num {
  position: relative;
}
.is-customer.top .section-quotation .quotation .quotation-data__label-wrap {
  display: inline-flex;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation .quotation-data__label-wrap {
    position: absolute;
    bottom: calc(100% + 12px);
    left: 0;
  }
  .is-customer.top .section-quotation .quotation .quotation-data__label-wrap > *:first-of-type {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation .quotation-data__text {
    display: flex;
    align-items: center;
  }
}
.is-customer.top .section-quotation .quotation .quotation-data__btn-wrap {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.is-customer.top .section-quotation .quotation .quotation-data__edit-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation .quotation-data__edit-btn {
    width: 40px;
    height: 40px;
  }
}
.is-customer.top .section-quotation .quotation .quotation-data__edit-btn .dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #252626;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .quotation .quotation-data__edit-btn .dot {
    width: 4px;
    height: 4px;
  }
}
.is-customer.top .section-quotation .quotation .quotation-data__edit-btn .dot + .dot {
  margin-left: 2px;
}
.is-customer.top .section-quotation .quotation .quotation-data__edit-btn.is-active {
  background-color: #666;
  border-color: transparent;
}
.is-customer.top .section-quotation .quotation .quotation-data__edit-btn.is-active .dot {
  background-color: #fff;
}
.is-customer.top .section-quotation .quotation .edit-list-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  visibility: hidden;
}
.is-customer.top .section-quotation .quotation .edit-list-bg.is-active {
  visibility: visible;
}
.is-customer.top .section-quotation .quotation .edit-list {
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translate(39px, 12px);
  z-index: 2;
  background-color: #fff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.is-customer.top .section-quotation .quotation .edit-list.is-active {
  visibility: visible;
  opacity: 1;
}
.is-customer.top .section-quotation .quotation .edit-list::before {
  content: "";
  position: absolute;
  right: 35px;
  bottom: 100%;
  z-index: 1;
  width: 8px;
  height: 10px;
  clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  background-color: #fff;
}
.is-customer.top .section-quotation .quotation .edit-list__inner {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}
.is-customer.top .section-quotation .quotation .edit-list__inner .edit-list__item + .edit-list__item {
  margin-left: 1px;
}
.is-customer.top .section-quotation .quotation .edit-list__btn {
  width: 50px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: end;
  color: #fff;
  padding: 8px;
  font-size: 1.1rem;
}
.is-customer.top .section-quotation .quotation .edit-list__btn_edit {
  background-color: #22b9b7;
}
.is-customer.top .section-quotation .quotation .edit-list__btn_copy {
  background-color: #228db9;
}
.is-customer.top .section-quotation .quotation .edit-list__btn_delete {
  background-color: #da294a;
}
.is-customer.top .section-quotation .quotation .edit-list__btn_pdf {
  background-color: #976fa6;
}
.is-customer.top .section-quotation .quotation .edit-list__btn-text {
  margin-top: 6px;
  display: block;
  line-height: 1;
}
.is-customer.top .section-quotation .stack-list__item {
  border-bottom: 1px solid #f6f6f6;
  padding: 4.6px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .stack-list__item {
    font-size: 1.6rem;
    padding: 10.1px 13px;
  }
}
.is-customer.top .section-quotation .stack-list__desc {
  font-size: 1.1rem;
  flex-shrink: 0;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .stack-list__desc {
    font-size: 1.4rem;
  }
}
.is-customer.top .section-quotation .total-price {
  padding: 12px 17px 0;
  display: flex;
  justify-content: space-between;
  line-height: 1;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .total-price {
    padding: 21px 13px 0;
  }
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .total-price__term {
    font-size: 1.8rem;
  }
}
.is-customer.top .section-quotation .total-price__desc {
  font-size: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .total-price__desc {
    font-size: 1.8rem;
  }
}
.is-customer.top .section-quotation .total-price__desc .unit {
  font-size: 1.2rem;
}
@media only screen and (min-width: 768px) {
  .is-customer.top .section-quotation .total-price__desc .unit {
    font-size: 1.4rem;
  }
}