.section-quotation {
  padding-top: 15px;
  padding-bottom: 50px;

  @include desktop {
    padding-top: 20px;
  }

  .headline {
    margin-bottom: 25px;

    &__title {
      padding-left: 3px;
      font-size: 2rem;
      line-height: 1;

      @include desktop {
        padding-left: 6px;
        font-size: 3.6rem;
      }
    }
  }

  .quotation {
    padding: 20px 15px 27px;
    position: relative;

    @include desktop {
      padding: 33px 60px 58px;
    }

    &__quotation-data {
      @include desktop {
        margin-left: -12px;
      }
    }

    &__group {
      padding-top: 8px;
      padding-bottom: 4px;

      @include desktop {
        padding-top: 16px;
      }
    }

    &__operate {
      margin-top: 23px;

      @include desktop {
        margin-top: 71px;
        display: flex;
        justify-content: space-between;
      }
    }

    &__operate-left {
      @include desktop {
        width: 710px;
      }
    }

    &__operate-right {
      @include desktop {
        margin-left: 30px;
        width: 340px;
      }
    }

    &__edit-list {
      margin-top: -8px;
      margin-bottom: 16px;

      @include desktop {
        margin-top: -16px;
      }
    }

    &__edit-item {
      + .quotation__edit-item {
        margin-top: 10px;
      }
    }

    &__total-price {
    }

    &__add-btn-wrap {
      margin-top: 25px;
      text-align: center;

      @include desktop {
        margin-top: 0;
        padding-top: 43px;
      }
    }

    &__add-btn {
      width: auto;
      padding-right: 25px;
      padding-left: 25px;
      transition: all 0.4s $easeOutQuint;

      &.is-active {
        background-color: #fff;
        color: #000;
        border-color: #d9d9d9;
      }

      @include desktop {
        width: 100%;
        max-width: 260px;
        border-radius: 40px;
        padding: 23.6px 16px;
        border-width: 2px;
        border-color: #000;
        padding-right: 15px;
        padding-left: 15px;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }

    &__back-btn {
      position: absolute;
      top: 0;
      right: 15px;
      transform: translate(0, -50%);
      z-index: 1;

      @include desktop {
        right: 60px;
      }
    }
  }

  .quotation-edit {
    background-color: #f6f6f6;
    border-radius: 5px;
    overflow: hidden;
    display: flex;

    @include desktop {
      background-color: #fff;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    }

    > *:not(:first-child) {
      flex-shrink: 0;
    }

    &_highlight {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 2.5px solid #cd2;
        pointer-events: none;
      }
    }

    &__heading {
      flex-grow: 1;
      font-size: 1.1rem;
      padding: 11.31px 10px 11.31px 21px;
      line-height: 1.7;

      @include desktop {
        font-size: 1.6rem;
        line-height: 1.5;
        padding: 16px 16px 16px 32px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-left: 6px;
    }

    &__btn {
      width: 50px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      @include desktop {
        transition: opacity 0.4s $easeOutQuint;
      }

      &:hover {
        @include desktop {
          opacity: $link-alpha;
        }
      }

      @include desktop {
        width: 70px;
      }

      &_edit {
        background-color: #47885e;
      }

      &_delete {
        background-color: #da294a;
        height: 100%;
      }
    }

    &__btn-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__btn-icon {
      margin-bottom: 7px;

      @include desktop {
        margin-bottom: 8px;
      }

      img {
        display: block;
        width: 25px;

        @include desktop {
          width: 30px;
        }
      }
    }

    &__btn-text {
      line-height: 1;

      @include desktop {
        font-size: 1.4rem;
      }
    }

    &__line {
      width: 1px;
      background-color: #fff;
    }
  }

  .total-price {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 16px;
    padding-left: 11px;

    @include desktop {
      width: calc((100% - 30px) / 2);
      margin-left: auto;
      padding-right: 0;
      padding-left: 0;
    }

    &__desc {
      font-size: 1.5rem;

      @include desktop {
        font-size: 2.4rem;
      }

      &::after {
        content: '円';
        font-size: 1.2rem;

        @include desktop {
          font-size: 1.6rem;
        }
      }
    }
  }

  .image-upload {
    &__bg {
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 16px 15px;

      @include desktop {
        border-radius: 10px;
        padding: 40px;
        margin-top: 50px;
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 13px;

      @include desktop {
        margin-bottom: 24px;
      }
    }

    .image-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      margin-bottom: 20px;

      @include desktop {
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
        margin-bottom: 30px;
      }

      &__image.is-active {
        outline: 2px solid $primary-color;
      }
    }

    .image-delete {
      display: flex;
      justify-content: center;
      margin-top: 13px;

      @include desktop {
        margin-top: 24px;
      }

      &__button {
        padding: 0.2em 0.5em;
        text-decoration: underline;
        text-underline-offset: 5px;
        color: inherit;

        &.is-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        @include desktop {
          padding: 0.5em 1.5em 0.5em 1em;
        }
      }

      &__button-inner {
        display: flex;
        align-items: center;
      }

      &__icon {
        display: inline-block;
        width: 15px;
        margin-right: 5px;

        @include desktop {
          width: 20px;
        }

        img {
          vertical-align: middle;
        }
      }
    }

    input[type='file'] {
      display: none;
    }

    &__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;

      @include desktop {
        column-gap: 40px;
      }

      .image-button {
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
        min-height: 40px;
        background-color: #000;
        border-radius: 5px;
        color: #fff;

        &_upload {
          background-color: $primary-color;
        }

        &.is-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &__icon {
          width: 13px;
          margin-right: 10px;
        }
      }
    }

    &__error {
      color: #a40000;
    }
  }

  .pdf-form {
    margin-top: 18px;

    @include desktop {
      margin-top: 50px;
    }

    &__button {
      color: #fff;
      background-color: #976fa6;
      padding: 0.5em;
      border-radius: 999px;
      min-height: 40px;
      width: 100%;
      max-width: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @include desktop {
        min-height: 50px;
        max-width: 250px;
      }

      img {
        margin-right: 11px;

        @include desktop {
          width: 28px;
          // margin-right: 20px;
        }
      }
    }
  }

  .accordion-panel {
    height: 0;
    overflow: hidden;
    transition: height 0.5s $easeInOutQuint;
  }

  .work-list {
    padding-top: 40px;

    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px 30px;
      padding-top: 97px;
    }

    @include desktop-lg {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      + .work-list__item {
        margin-top: 15px;

        @include desktop {
          margin-top: 0;
        }
      }
    }
  }

  .work-detail-list {
    &__head {
      color: #fff;
      background-color: #669944;
      padding: 10px 19px;
      border-radius: 5px 5px 0 0;

      @include desktop {
        border-radius: 10px 10px 0 0;
      }

      @include desktop {
        padding: 10.6px 19px;
      }

      &_lawn {
        background-color: #9eb057;
      }

      &_felling {
        background-color: #998e44;
      }

      &_weeding {
        background-color: #70ad7a;
      }

      &_chemical {
        background-color: #5fb3af;
      }

      &_fertilization {
        background-color: #abb100;
      }

      &_others {
        background-color: #aaa;
      }
    }

    &__body {
      border: 1px solid #e5e5e5;
      overflow: hidden;
      border-radius: 0 0 5px 5px;

      @include desktop {
        border-radius: 0 0 20px 20px;
      }
    }

    &__item {
      + .work-detail-list__item {
        border-top: 1px solid #e5e5e5;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 49px;
      padding: 12px 40px 13px 62px;
      background: #fafafa;

      @include desktop {
        min-height: 70px;
        padding: 1em 40px 1em 82px;
      }

      &_options {
        padding: 12px 40px 13px 20px;

        @include desktop {
          padding: 1em 40px 1em 20px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translate(0, -50%) rotate(45deg);
        z-index: 1;
        width: 12px;
        height: 12px;
        border: {
          top: 2px solid #000;
          right: 2px solid #000;
        }

        @include desktop {
          right: 20px;
          transition: transform 0.5s $easeOutQuint;
        }
      }

      &:hover {
        &::after {
          transform: translate(2px, -50%) rotate(45deg);
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 45px;

      @include desktop {
        width: 60px;
        left: 41px;
      }

      img {
        width: 100%;
      }
    }

    &__title {
      @include mobile {
        font-size: 1.5rem;
      }
    }
  }
}
