.section-customer {
  .content {
    @include desktop {
      margin-top: 60px;
    }
    @include mobile {
      margin-top: 20px;
    }
  }

  .customer {
    position: relative;

    @include desktop {
      padding-top: 60px;
      padding-bottom: 45px;
    }
    @include mobile {
      padding-top: 45px;
      padding-bottom: 35px;
    }

    &__back-btn {
      position: absolute;
      top: 0;
      right: 15px;
      transform: translate(0, -50%);
      z-index: 1;
    }

    &-add {
      &-switch {
        display: flex;
        justify-content: center;

        &-type {
          padding: 0 15px;

          input[type='radio'] {
            display: none;

            & + label {
              cursor: pointer;
              padding-left: 2.5em;
              position: relative;

              &::before {
                content: '';
                display: block;
                background: #f6f6f6;
                border: 1px solid #e5e5e5;
                border-radius: 50%;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                @include desktop {
                  width: 28px;
                  height: 28px;
                }
                @include mobile {
                  width: 20px;
                  height: 20px;
                }
              }
              &::after {
                content: '';
                background: #8c8c8c;
                border: 1px solid transparent;
                border-radius: 50%;
                opacity: 0;
                padding: 2px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.3s ease 0s;

                @include desktop {
                  width: 14px;
                  height: 14px;
                  left: 7px;
                }
                @include mobile {
                  width: 10px;
                  height: 10px;
                  left: 5px;
                }
              }
            }
            &:checked {
              & + label {
                &::after {
                  opacity: 1;
                }
              }
            }
          }

          label {
            display: flex;
            align-items: center;
          }

          .switch-icon {
            display: block;
            text-align: center;

            @include desktop {
              width: 35px;
              height: 30px;
            }
            @include mobile {
              width: 30px;
              height: 25px;
            }

            img {
              height: 100%;
              width: auto;
            }
          }

          .switch-name {
            display: block;
            margin-left: 0.5em;

            @include desktop {
              font-size: 1.8rem;
            }
            @include mobile {
              font-size: 1.5rem;
            }
          }
        }
      }

      &-info {
        @include desktop {
          margin-top: 50px;
          padding: 0 60px;
        }
        @include mobile {
          margin-top: 30px;
          padding: 0 35px;
        }

        &-company {
          margin-bottom: 50px;
          display: none;

          @include mobile {
            margin-bottom: 30px;
          }

          &.is-show {
            display: block;
          }
        }

        // &.private {
        //   display: none;
        // }

        &.corporation {
          display: none;
        }

        &-box {
          @include desktop {
            display: flex;
          }

          &:not(:first-of-type) {
            margin-top: 35px;
          }
        }

        &__title {
          @include desktop {
            font-size: 1.6rem;
            flex-shrink: 0;
            min-width: 200px;
            padding: 0.75em 0;
          }
          @include mobile {
            font-size: 1.2rem;
          }
        }

        &-inner {
          @include desktop {
            flex-grow: 1;
          }
          @include mobile {
            margin-top: 10px;
          }

          &.name {
            .customer-add-info-field:not(:first-of-type) {
              margin-top: 20px;
            }
          }
        }

        &-field {
          &:not(:first-of-type) {
            @include desktop {
              margin-top: 20px;
            }
            @include mobile {
              margin-top: 10px;
            }
          }

          &-inner {
            position: relative;
          }

          &.flex {
            display: flex;
          }

          .half {
            width: calc(50% - 5px);
          }

          .hyphen {
            display: block;
            padding-top: 0.7em;
            margin: 0 10px;
          }

          input {
            width: 100%;
            background: #fafafa;
            padding: 0.7em 1.25em;
            border: 1px solid #e5e5e5;
            border-radius: 3px;

            &::placeholder {
              color: #b3b3b3;
            }
            &::-ms-input-placeholder {
              color: #b3b3b3;
            }

            & + input {
              margin-left: 10px;
            }

            &.js-address-zip {
              @include mobile {
                width: 100%;
              }
            }
          }

          button {
            color: #fff;
            padding: 0.5em;
            background: #595959;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            margin-left: 10px;

            @include desktop {
              font-size: 1.2rem;
              min-width: 100px;
              transition: 150ms $easeInOutSine;
            }
            @include mobile {
              font-size: 1rem;
              min-width: 75px;
            }

            &:hover {
              @include desktop {
                background: #fff;
                color: #000;
              }
            }
          }

          .select-box {
            position: relative;

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              @include triangle(bottom, 7px, 7px, #486a00);
              pointer-events: none;
            }

            select {
              width: 100%;
              padding: 0.75em 1.25em;
              padding-right: 2.25em;
              box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.1);
              border-radius: 3px;
              color: inherit;
            }
          }

          .notes {
            display: block;
            text-align: right;
            font-size: 1rem;
            margin-top: 0.25em;
            font-weight: 400;
          }
        }
      }
    }

    .btn-wrap {
      margin-top: 55px;

      .primary-btn.inactive {
        background: #aaa;
        pointer-events: none;
      }
    }
  }

  .customer-delete {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .customer-delete-button {
    padding: 0.2em 0.5em;
    text-decoration: underline;
    text-underline-offset: 5px;
    color: inherit;
  }
}
