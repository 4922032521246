.section-customer {
  .content {
    @include desktop {
      display: flex;
      margin-top: 60px;
    }
    @include mobile {
      margin-top: 25px;
    }
  }

  .btn-wrap {
    @include desktop {
      width: 25%;
    }

    .primary-btn {
      @include desktop {
        max-width: none;
        font-size: 1.8rem;
        padding: 2.25rem 1.5rem;
        border-radius: 10px;
      }
    }
  }

  .customer {
    position: relative;
    // overflow: hidden;

    @include desktop {
      width: 75%;
      margin-left: 50px;
    }
    @include mobile {
      margin-top: 20px;
    }

    &-settings {
      background: $bgcolor;
      border-radius: 10px 10px 0 0;

      @include desktop {
        padding: 30px 15px;
      }
      @include mobile {
        padding: 25px 15px;
      }
    }

    &-index {
      &__search {
        position: relative;
        display: flex;
        align-items: center;
        background: #fff;
        overflow: hidden;
        max-width: 500px;
        margin: auto;

        @include desktop {
          border-radius: 5px;
        }
        @include mobile {
          border-radius: 2px;
        }

        .search-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          cursor: pointer;

          @include desktop {
            width: 55px;
            height: 50px;
            padding-left: 5px;
          }
          @include mobile {
            width: 35px;
            height: 35px;
          }

          img {
            @include desktop {
              width: 40%;
              height: 40%;
            }
            @include mobile {
              width: 50%;
              height: 50%;
            }
          }
        }

        input {
          cursor: pointer;
          display: block;
          background: #fff;
          width: 100%;
          padding-right: 1em;

          @include desktop {
            padding: 0.65em 0.5em;
          }
          @include mobile {
            padding: 0.75em 0;
          }
        }
      }
    }

    &-controller {
      @include desktop {
        margin-top: 20px;
      }
      @include mobile {
        margin-top: 15px;
      }

      &-inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 360px;
        margin: auto;
      }

      .sort {
        &-list {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          padding: 0.75em 0;
          position: absolute;
          bottom: -10px;
          left: 0;
          transform: translateY(calc(100% - 20px));
          transition: opacity 400ms $easeOutCubic,
            visibility 400ms $easeOutCubic, transform 400ms $easeOutCubic;
          background-color: #fff;
          z-index: 1;

          &.is-open {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
            transform: translateY(100%);
          }
        }

        &-item {
          position: relative;

          &:not(:first-of-type) {
            border-top: 1px solid #f6f6f6;
          }

          &.is-current {
            &::before {
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              background-image: url(../img/customer/icon-check.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }

          button {
            color: #000;
            display: block;
            font-size: 1.5rem;
            padding: 0.4em 1.75em;
            padding-left: 2.25em;
          }
        }
      }

      .narrow {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        transform: translateY(calc(100% - 20px));
        transition: opacity 400ms $easeOutCubic, visibility 400ms $easeOutCubic,
          transform 400ms $easeOutCubic;
        padding: 15px;
        padding-bottom: 25px;
        background-color: #fafafa;
        z-index: 1;

        &.is-open {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
          transform: translateY(100%);
        }

        &-inner {
          position: relative;
          padding-top: 10px;
        }

        &-list {
          padding-left: 20px;
          padding-right: 40px;
        }

        &-item {
          display: flex;
          align-items: center;

          &:not(:first-of-type) {
            margin-top: 15px;
          }
        }

        &__name {
          flex-shrink: 0;
          padding-right: 1em;
        }

        &-select {
          position: relative;
          flex-grow: 1;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            @include triangle(bottom, 7px, 7px, #486a00);
            pointer-events: none;
          }

          select {
            width: 100%;
            background: #fff;
            box-shadow: 2.5px 2.5px 2.5px rgba($color: #000000, $alpha: 0.1);
            border-radius: 3px;
            padding: 0.75em 1.25em;
            padding-right: 2em;
            color: inherit;
          }
        }

        &-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 40px;
          padding: 0 10px;

          .btn {
            border-radius: 35px;
            margin: 0 5px;
            width: calc(50% - 10px);
            padding: 0.7em;
            color: inherit;
            text-align: center;

            &.clear {
              background: #fff;
              border: 1px solid #d9d9d9;
            }

            &.submit {
              color: #fff;
              background: #262626;
              border: 1px solid #262626;
            }
          }
        }

        &-close {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;

          &::before,
          &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 50%;
            left: 50%;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    &-sort,
    &-narrow {
      width: calc((100% - 15px) / 2);
    }

    &-btn {
      display: block;
      position: relative;
      cursor: pointer;
      background: #fff;
      border-radius: 50px;
      font-size: 1.5rem;
      padding: 0.55em 0.5em;
      width: 100%;
      max-width: 170px;
      color: inherit;

      @include desktop {
        transition: 200ms $easeInOutSine;
      }

      &:hover {
        @include desktop {
          color: #fff;
          background: #595959;
        }

        &.sort-btn {
          &::before {
            @include desktop {
              background-image: url(../img/customer/icon-sort_wh.svg);
            }
          }
        }

        &.narrow-btn {
          &::before {
            @include desktop {
              background-image: url(../img/customer/icon-narrow_wh.svg);
            }
          }
        }
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        pointer-events: none;

        @include desktop {
          left: 25px;
          width: 18px;
          height: 18px;
          transition: 200ms $easeInOutSine;
        }
        @include mobile {
          left: 17px;
          width: 15px;
          height: 15px;
        }
      }

      &.sort-btn {
        padding-left: 10%;
        margin-left: auto;

        &::before {
          background-image: url(../img/customer/icon-sort.svg);
        }
      }

      &.narrow-btn {
        padding-left: 20%;

        &::before {
          background-image: url(../img/customer/icon-narrow.svg);
        }
      }

      &.is-active {
        color: #fff;
        background: #262626;

        &.sort-btn {
          &::before {
            background-image: url(../img/customer/icon-sort_wh.svg);
          }
        }

        &.narrow-btn {
          &::before {
            background-image: url(../img/customer/icon-narrow_wh.svg);
          }
        }
      }
    }

    &-result {
      &-item {
        line-height: 1;
        border-top: 1px solid #f6f6f6;

        a {
          position: relative;
          display: flex;

          @include desktop {
            padding: 2em 50px 1.5em;
            padding-right: 75px;
          }
          @include mobile {
            padding: 2em 2em 1.5em;
            padding-right: 30px;
          }

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 10px;
            height: 10px;
            border-right: 2px solid #030000;
            border-top: 2px solid #030000;

            @include desktop {
              right: 50px;
            }
            @include mobile {
              right: 20px;
            }
          }
        }

        &-inner {
          padding-left: 1em;
        }
      }

      &__icon {
        flex-shrink: 0;
        text-align: center;

        @include desktop {
          width: 30px;
          height: 25px;
        }
        @include mobile {
          width: 20px;
          height: 18px;
        }

        img {
          width: auto;
          height: 100%;
        }
      }

      &__company {
        margin-bottom: 5px;
      }

      &__name {
        word-break: break-all;

        @include desktop {
          font-size: 2.1rem;
        }
        @include mobile {
          font-size: 1.8rem;
        }

        &::after {
          content: '様';
          margin-left: 0.6rem;
          font-size: 1.2rem;
        }
      }

      &__address {
        @include desktop {
          font-size: 1.2rem;
          margin-top: 0.75em;
        }
        @include mobile {
          font-size: 1rem;
          margin-top: 0.5em;
        }
      }
    }
  }
}
